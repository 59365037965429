import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import update_profile from "../../requests/update_profile";
import { fetch_profile } from "../../ducks/profile";
import reset_password from "../../requests/reset_password";
import * as Yup from "yup";
import Validators from "../../validators";

interface Props extends IProps {
  onSuccess?: VoidFunction;
}

type Values = {
  firstname: string;
  lastname: string;
  primary_email: string;
  username: string;
  activity_current_title: string;
  user_current_activity: string;
  user_status: string;
  addtl_info: string;
};

interface State {
  loading: boolean;
}

class EditProfile extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    const { profile } = this.props.appState;
    try {
      this.setState({ loading: true });

      /* await request(...) */
      await update_profile({ ...values, id: profile.id, user_temp: 0 });
      this.setState({ loading: false });
      this.props.dispatch(flashMessage(`Successfully Edited Profile`));
      this.props.dispatch(fetch_profile(profile.id));
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  resetPassword = async () => {
    try {
      await reset_password();
      this.setState({ loading: false });
      this.props.dispatch(
        flashMessage("Successfully sent password reset link")
      );
      this.props.onSuccess && this.props.onSuccess();
    } catch (err) {
      this.props.dispatch(
        flashErrorMessage("An error occured while trying to reset password")
      );
    }
  };

  render() {
    const { profile } = this.props.appState;

    const initialValues = {
      firstname: profile.firstname,
      lastname: profile.lastname,
      primary_email: profile.primary_email,
      username: profile.username,
      activity_current_title: profile.activity_current_title,
      user_current_activity: profile.user_current_activity,
      user_status: profile.user_status,
      addtl_info: profile.addtl_info,
    };
    const validationSchema = Yup.object().shape({
      firstname: Yup.string().required("required"),
      lastname: Yup.string().required("required"),
      primary_email: Yup.string()
        .test("email", "must be an email", (value) =>
          Validators.emailBase.isValid(value)
        )
        .test(
          "unique",
          "email already taken",
          (value) =>
            value === initialValues.primary_email ||
            Validators.emailValidator.isValid(value)
        ),
      username: Yup.string()
        .test(
          "username",
          "username can only have numbers, letters, _s and .s",
          (value) => Validators.usernameBase.isValid(value)
        )
        .test(
          "usernameUnique",
          "username is already taken",
          (value) =>
            value === initialValues.username ||
            Validators.usernameValidator.isValid(value)
        ), //Validators.usernameValidator,
    });
    const formName = "editprofile";

    return (
      <>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            touched,
            errors,
          }) => (
            <Form className={`wf wf-form wf-${formName}`} name={formName}>
              <FormField
                onBlur={handleBlur}
                labelText="Firstname"
                formName={formName}
                itemName="firstname"
                value={values.firstname}
                onChange={handleChange}
              />
              {errors.firstname && touched.firstname && (
                <div className="wib-error">{errors.firstname}</div>
              )}
              <FormField
                onBlur={handleBlur}
                labelText="Lastname"
                formName={formName}
                itemName="lastname"
                value={values.lastname}
                onChange={handleChange}
              />
              {errors.lastname && touched.lastname && (
                <div className="wib-error">{errors.lastname}</div>
              )}
              <FormField
                onBlur={handleBlur}
                labelText="Primary Email"
                formName={formName}
                itemName="primary_email"
                value={values.primary_email}
                onChange={handleChange}
              />
              {errors.primary_email && touched.primary_email && (
                <div className="wib-error">{errors.primary_email}</div>
              )}
              <FormField
                onBlur={handleBlur}
                labelText="Username"
                formName={formName}
                itemName="username"
                value={values.username}
                onChange={handleChange}
              />
              {errors.username && touched.username && (
                <div className="wib-error">{errors.username}</div>
              )}
              <FormField
                labelText="Activity Title"
                formName={formName}
                itemName="activity_current_title"
                value={values.activity_current_title}
                onChange={handleChange}
              />
              <FormField
                labelText="Current Activity"
                formName={formName}
                itemName="user_current_activity"
                value={values.user_current_activity}
                onChange={handleChange}
              />
              <FormField
                labelText="Status"
                formName={formName}
                itemName="user_status"
                value={values.user_status}
                onChange={handleChange}
              />
              <FormField
                labelText="Additional Info"
                formName={formName}
                itemName="addtl_info"
                value={values.addtl_info}
                onChange={handleChange}
                customInput={({ className }) => (
                  <textarea
                    name="addtl_info"
                    value={values.addtl_info}
                    className={className}
                    onChange={handleChange}
                  />
                )}
              />
              <FormSubmit
                disabled={this.state.loading}
                formName={formName}
                labelText="Submit"
                itemName="submit"
                value="submit"
              />
              {this.state.loading && "loading"}
            </Form>
          )}
        </Formik>
        <div className="password-reset" onClick={this.resetPassword}>
          Click here to reset your password
        </div>
      </>
    );
  }
}

export default connector(EditProfile);
