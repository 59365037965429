import React, { Component } from "react";
import path from "path";
import PDFModal, { Props as PDFProps } from "./PDFModal";
import STLModal from "./STLModal";

export interface Props extends PDFProps {}

export default class WIBMediaModal extends Component<Props, any> {
  render() {
    return (
      <>
        {" "}
        {path.extname(this.props.docPath) === ".pdf" && (
          <PDFModal {...this.props} />
        )}
        {(path.extname(this.props.docPath) === ".stl" ||
          path.extname(this.props.docName) === ".doc") && (
          <STLModal {...this.props} />
        )}
      </>
    );
  }
}
