import Profile, { initial_profie } from "../types/Profile";
import { AnyAction, Dispatch } from "redux";
import get_profile from "../requests/get_profile";
import WIBError from "../types/WIBError";
import { flashErrorMessage } from "redux-flash";
import { set_loading } from "./loading";

export const RECEIVE_PROFILE = "wib/profile/RECEIVE_PROFILE";

export type ReceiveProfileAction = { type: string; new_profile: Profile };

export default function reducer(
  state: Profile = initial_profie,
  action: AnyAction
) {
  switch (action.type) {
    case RECEIVE_PROFILE:
      const receive_profile_action = action as ReceiveProfileAction;
      return receive_profile_action.new_profile;
    default:
      return state;
  }
}

export function receive_profile(profile: Profile): ReceiveProfileAction {
  return { type: RECEIVE_PROFILE, new_profile: profile };
}

export function fetch_profile(profile_id: number) {
  return async function (dispatch: Dispatch) {
    dispatch(set_loading(true));
    try {
      const result = await get_profile(profile_id);
      dispatch(receive_profile(result));
      dispatch(set_loading(false));
    } catch (err) {
      dispatch(set_loading(false));
      const wiberror = err as WIBError;
      flashErrorMessage(wiberror.message);
    }
  };
}
