import User from "../types/User";
import { AnyAction, Dispatch } from "redux";
import { set_loading } from "./loading";
import get_users from "../requests/get_users";

export const FETCH_USERS = "wib/projects/FETCH_USERS";
export const RECEIVE_USERS = "wib/projects/RECEIVE_USERS";

export type FetchUsersAction = { type: string };
export type ReceiveUsersAction = { type: string; users: User[] };

export default function reducer(state: User[] = [], action: AnyAction) {
  switch (action.type) {
    case RECEIVE_USERS:
      const receive_user_action = action as ReceiveUsersAction;
      return receive_user_action.users;
    default:
      return state;
  }
}

export function fetch_users() {
  return async function (dispatch: Dispatch) {
    dispatch(set_loading(true));
    const users = await get_users();
    dispatch(set_loading(false));
    dispatch(receive_users(users));
  };
}

export function receive_users(users: User[]) {
  return { type: RECEIVE_USERS, users };
}
