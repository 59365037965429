import React from "react";
import axios from "axios";

export default class TextView extends React.Component<
  { file: string },
  { fileContents: string }
> {
  state = { fileContents: "" };

  async componentDidMount() {
    const fileContents = (await axios.get(this.props.file)).data;
    this.setState({ fileContents });
  }

  render() {
    return <div className="wib-module-textview">{this.state.fileContents}</div>;
  }
}
