import React, { Component } from "react";
import connector, { IProps } from "../../common_connector";
import CollectionItemTitle from "../Atoms/CollectionItemTitle";
import AddButton from "../Atoms/AddButton";
import { flashSuccessMessage, flashErrorMessage } from "redux-flash";
import { fetch_project } from "../../ducks/project";
import create_module from "../../requests/create_module";
import Module from "./Module";
import {
  Draggable,
  Droppable,
  DragDropContext,
  DropResult,
} from "react-beautiful-dnd";
import _ from "lodash";
import update_section_order from "../../requests/update_section_order";

interface Props extends IProps {
  onRefreshTags: VoidFunction;
}

interface State {
  newModuleOrder: number[];
  oldModuleOrder: number[];
}

class Modules extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const moduleOrder = props.appState.project.modules.map(
      (module) => module.id
    );
    this.state = { newModuleOrder: moduleOrder, oldModuleOrder: moduleOrder };
  }

  componentDidUpdate(oldProps: Props) {
    const moduleOrder = this.props.appState.project.modules.map(
      (module) => module.id
    );
    if (
      oldProps.appState.project.modules !== this.props.appState.project.modules
    ) {
      this.setState({
        newModuleOrder: moduleOrder,
        oldModuleOrder: moduleOrder,
      });
    }
  }

  createModule = async () => {
    const { project } = this.props.appState;
    try {
      await create_module({
        project_id: project.project_id,
      });
      this.props.dispatch(flashSuccessMessage("Successfully successMessage"));
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(
        flashErrorMessage("An error occured while errorMessage")
      );
    }
  };

  reorder = <T extends unknown>(
    list: T[],
    startIndex: number,
    endIndex: number
  ): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newState = {
      ...this.state,
      newModuleOrder: this.reorder(
        this.state.newModuleOrder,
        result.source.index,
        result.destination.index
      ),
    };

    this.setState(newState);
    this.submit(newState);
  };

  submit = async (state: State) => {
    const { project } = this.props.appState;
    const { newModuleOrder, oldModuleOrder } = state;
    try {
      await update_section_order({
        project_id: project.project_id,
        section_order: state.newModuleOrder.join(","),
      });
      this.setState({ newModuleOrder, oldModuleOrder: newModuleOrder });
    } catch (err) {
      console.log(err);
      this.setState({ newModuleOrder: oldModuleOrder });
      this.props.dispatch(
        flashErrorMessage("An error occured while reordering sections")
      );
    }
  };

  render() {
    return (
      <div className="wib-modules-container">
        <CollectionItemTitle name="Modules" text="Modules" />

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {_.sortBy(this.props.appState.project.modules, (module) =>
                  this.state.newModuleOrder.indexOf(module.id)
                ).map((module, index) => (
                  <Draggable
                    key={module.id}
                    draggableId={`${module.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <img
                          src=""
                          className="wib-drag-handle"
                          {...provided.dragHandleProps}
                        />
                        <Module
                          onRefreshTags={this.props.onRefreshTags}
                          key={module.id}
                          moduleID={module.id}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <AddButton text="Add Module" onClick={this.createModule} />
      </div>
    );
  }
}

export default connector(Modules);
