import React, { MouseEventHandler } from "react";
import EditIcon from "../../Atoms/EditIcon";
import IProcess from "../../../types/Process";
import Text from "../../Atoms/Text";
import "react-quill/dist/quill.snow.css";
import Step from "./Step";
import _ from "lodash";
import connector, { IProps } from "../../../common_connector";
import uniqid from "uniqid";
import AddButton from "../../Atoms/AddButton";
import CreateStep from "../../Forms/CreateStep";
import IconButton from "../../Atoms/IconButton";
import DeleteIcon from "../../Atoms/DeleteIcon";
import { expand_process, collapse_process } from "../../../ducks/expanded";
import WibModal from "../../Atoms/WibModal";

interface Props extends IProps {
  process: IProcess;
  onClick?: MouseEventHandler;
  onDelete: VoidFunction;
}

interface State {
  creating: boolean;
}

class Process extends React.Component<Props, State> {
  state = { creating: false, expanded: false };

  render() {
    const expanded = _.some(this.props.appState.expanded, {
      process_id: this.props.process.id,
    });
    const { project } = this.props.appState;
    const { process } = this.props;
    const owner = _.find(project.users, { user_id: process.owner_id })!;
    return (
      <>
        <WibModal
          title="Create Step"
          onDismiss={() => this.setState({ creating: false })}
          isOpen={this.state.creating}
        >
          <CreateStep
            processID={process.id}
            onSuccess={() => this.setState({ creating: false })}
          />
        </WibModal>
        <div
          className={`process-row-container process-row-container-${
            expanded ? "expanded" : "collapsed"
          }`}
        >
          <IconButton
            onClick={() =>
              expanded
                ? this.props.dispatch(collapse_process(process.id))
                : this.props.dispatch(expand_process(process.id))
            }
            name={`process-expand-chevron-${
              expanded ? "expanded" : "collapsed"
            }`}
          />
          <div
            className={`process-row-content`}
            onClick={() =>
              expanded
                ? this.props.dispatch(collapse_process(process.id))
                : this.props.dispatch(expand_process(process.id))
            }
          >
            <Text name="process-title">{process.title}</Text>
            <Text name="process-objective">{process.objective}</Text>
            <div className="process-metadata-container">
              <Text name="process-metadata-key">files:</Text>
              <Text name="process-metadata-value">{process.filecount}</Text>
              <Text name="process-metadata-key">steps:</Text>
              <Text name="process-metadata-value">{process.steps.length}</Text>
            </div>
            <div className="process-users-container">
              <Text name="process-user">{`${owner.firstname} ${owner.lastname}`}</Text>
            </div>
            <div className="wib-actions-container wib-actions-container-process">
              <EditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  if (this.props.onClick) this.props.onClick(e);
                }}
              />
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.onDelete();
                }}
              />
            </div>
          </div>
          <div
            className="wib-steps-containter"
            style={{
              display: expanded ? "block" : "none",
            }}
          >
            {process.steps.map((step) => (
              <Step processID={process.id} stepID={step.id} key={uniqid()} />
            ))}
            <AddButton
              onClick={() => this.setState({ creating: true })}
              text="Create Step"
            />
          </div>
        </div>
      </>
    );
  }
}

export default connector(Process);
