import React from "react";

export default (props: {
  formName: string;
  itemName: string;
  labelText?: string;
  extraInfo?: string;
  disabled?: boolean;
  value: any;
}) => (
  <div className="wff-row wff-row-button">
    <div
      className={`wff-extrainfo-wrap wff-extrainfo-wrap-${props.formName}-${props.itemName}`}
    >
      <h5
        className={`wff-extrainfo wff-extrainfo-${props.formName}-${props.itemName}`}
      >
        {props.extraInfo}
      </h5>
    </div>
    <div
      className={`wff-label-wrap wff-label-wrap-${props.formName}-${props.itemName}`}
    >
      <h3 className={`wff-label wff-label-${props.formName}-${props.itemName}`}>
        {props.labelText}
      </h3>
    </div>
    <div
      className={`wff-wrap wff-wrap-text wff-wrap-${props.formName}-${props.itemName}`}
    >
      <input
        disabled={props.disabled || false}
        type="submit"
        value={props.value}
        className={`wff wff-button wff-submit`}
      />{" "}
    </div>
  </div>
);
