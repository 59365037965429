import Project, { initial_project } from "../types/Project";
import { AnyAction, Dispatch } from "redux";
import _ from "lodash";
import Expanded from "../types/Expanded";

export const EXPAND_PROCESS = "wib/expanded/EXPAND_PROCESS";
export const COLLAPSE_PROCESS = "wib/expanded/COLLAPSE_PROCESS";
export const EXPAND_STEP = "wib/expanded/EXPAND_STEP";
export const COLLAPSE_STEP = "wib/expanded/COLLAPSE_STEP";

type ExpandProcessAction = { type: string; process_id: number };
type CollapseProcessAction = { type: string; process_id: number };
type ExpandStepAction = { type: string; process_id: number; step_id: number };
type CollapseStepAction = { type: string; process_id: number; step_id: number };

export default function reducer(state: Expanded[] = [], action: AnyAction) {
  switch (action.type) {
    case EXPAND_PROCESS:
      const expand_process_action = action as ExpandProcessAction;
      return [
        ...state,
        { process_id: expand_process_action.process_id, steps: [] },
      ];
    case COLLAPSE_PROCESS:
      const collapse_process_action = action as CollapseProcessAction;
      return state.filter(
        (expanded) => expanded.process_id !== collapse_process_action.process_id
      );
    case EXPAND_STEP:
      const expand_step_action = action as ExpandStepAction;
      return state.map((expanded) => {
        if (expanded.process_id === expand_step_action.process_id) {
          return {
            ...expanded,
            steps: [...expanded.steps, expand_step_action.step_id],
          };
        }
        return expanded;
      });
    case COLLAPSE_STEP:
      const collapse_step_action = action as CollapseStepAction;
      return state.map((expanded) => {
        if (expanded.process_id === collapse_step_action.process_id) {
          return {
            ...expanded,
            steps: expanded.steps.filter(
              (step) => step !== collapse_step_action.step_id
            ),
          };
        }
        return expanded;
      });
    default:
      return state;
  }
}

export function expand_process(process_id: number): ExpandProcessAction {
  return { type: EXPAND_PROCESS, process_id };
}

export function collapse_process(process_id: number): CollapseProcessAction {
  return { type: COLLAPSE_PROCESS, process_id };
}

export function expand_step(
  process_id: number,
  step_id: number
): ExpandStepAction {
  return { type: EXPAND_STEP, process_id, step_id };
}

export function collapse_step(
  process_id: number,
  step_id: number
): CollapseStepAction {
  return { type: COLLAPSE_STEP, process_id, step_id };
}
