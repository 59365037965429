import BASE_URL, { STEP_CONTENT_URL } from "./base_url";
import request from "./request_helper";

export interface Args {
  project_id: number;
  process_id: number;
  step_id: number;
}

export default async function execute(args: Args): Promise<any> {
  const files_path = `/${args.project_id}/${args.process_id}/${args.step_id}`;
  const res = await request({
    method: "GET",
    baseURL: BASE_URL,
    url: `projects/${files_path}/step_files`,
  });
  return res.files.map((file: string) => ({
    name: file,
    path: `${STEP_CONTENT_URL}/${files_path}/${file}`,
  }));
}
