import React from "react";
import AppState from "../../../types/AppState";
import { connect } from "react-redux";
import uniqid from "uniqid";
import Collab from "./Collab";
import AddButton from "../../Atoms/AddButton";
import connector, { IProps } from "../../../common_connector";
import EditCollab from "../../Forms/EditCollab";
import CollectionItemTitle from "../../Atoms/CollectionItemTitle";
import UserContext from "../../../UserContext";
import WibConfirmModal from "../../Atoms/WibConfirmModal";
import update_collabtype from "../../../requests/update_collabtype";
import { flashErrorMessage, flashSuccessMessage } from "redux-flash";
import { fetch_project } from "../../../ducks/project";
import WibModal from "../../Atoms/WibModal";
import InviteUser from "../../InviteUser";
import AddCollabRole from "../../Forms/AddCollabRole";

interface Props extends IProps {
  canEdit: () => boolean;
}

interface State {
  selectedUser: number;
  editing: boolean;
  creating: boolean;
  confirming: boolean;
  toDelete: number;
}

class Collabs extends React.Component<Props, State> {
  state = {
    selectedUser: -1,
    editing: false,
    creating: false,
    confirming: false,
    toDelete: -1,
  };

  deleteCollab = async () => {
    const { project } = this.props.appState;
    try {
      await update_collabtype({
        project_id: project.project_id,
        user_id: this.state.toDelete,
        collab_type: 3,
      });
      this.setState({ confirming: false });
      this.props.dispatch(
        flashSuccessMessage("Successfully removed collaborator from project")
      );
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(
        flashErrorMessage("An error occured while removing the collaborator")
      );
    }
  };

  render() {
    const { project } = this.props.appState;
    return (
      <>
        <WibConfirmModal
          isOpen={this.state.confirming}
          onConfirm={this.deleteCollab}
          onCancel={() => this.setState({ confirming: false })}
        />
        <WibModal
          title="Edit Collaborator"
          isOpen={this.state.editing}
          onDismiss={() => this.setState({ editing: false })}
        >
          <EditCollab
            onSuccess={() => this.setState({ editing: false })}
            selectedUser={this.state.selectedUser}
          />
        </WibModal>
        <WibModal
          title="Add Collaborator"
          isOpen={this.state.creating}
          onDismiss={() => this.setState({ creating: false })}
        >
          <AddCollabRole
            isRole={false}
            onSuccess={() => this.setState({ creating: false })}
          />
          {/* <InviteUser onSuccess={() => this.setState({ creating: false })} /> */}
        </WibModal>
        <div className="collabs">
          <CollectionItemTitle name="collaborators" text="Project Team" />
          {project.users.map((user) => (
            <Collab
              onDelete={() =>
                this.setState({ confirming: true, toDelete: user.user_id })
              }
              onEdit={() => {
                if (this.props.canEdit()) {
                  this.setState({
                    selectedUser: user.user_id,
                    editing: true,
                  });
                }
              }}
              key={uniqid()}
              contributor={user}
            />
          ))}
          {project.users.length % 2 !== 0 && (
            <div className="collab blank-collab" />
          )}
          <AddButton
            onClick={() => {
              if (this.props.canEdit()) {
                this.setState({ creating: true });
              }
            }}
            text="Add Collaborator"
          />
        </div>
      </>
    );
  }
}

export default connector(Collabs);
