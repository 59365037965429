import React from "react";
import connector, { IProps } from "../../../common_connector";
import { FileDropper } from "./FileDropper";
import CollectionItemTitle from "../../Atoms/CollectionItemTitle";
import { FilePrompt } from "./FilePrompt";
import _, { uniqueId } from "lodash";
import { flashErrorMessage, flashSuccessMessage, flashMessage } from "redux-flash";
import fetch_module_files from "../../../requests/get_module_files";
import Container from "./Container";
import Text from "../../Atoms/Text";
import EditIcon from "../../Atoms/EditIcon";
import DeleteIcon from "../../Atoms/DeleteIcon";
import IconButton from "../../Atoms/IconButton";
import { DH_UNABLE_TO_CHECK_GENERATOR } from "constants";
import update_module_files from "../../../requests/update_module_files";
import create_container from "../../../requests/create_container";
import { MODULE_CONTENT_URL } from "../../../requests/base_url";
import getCurrentUserID from "../../../getCurrentUserID";
import WibConfirmModal from "../../Atoms/WibConfirmModal";
import { fetch_project } from "../../../ducks/project";
import delete_container from "../../../requests/delete_container";
import ModuleContainer from "../../../types/Container";
import IModule from "../../../types/Module";
import update_container_order from "../../../requests/update_container_order";
import uniqid from "uniqid";
import path from "path";
import get_module from "../../../requests/get_module";
import { Draggable, Droppable, DragDropContext, DropResult } from "react-beautiful-dnd";
import update_module from "../../../requests/update_section";
import delete_section from "../../../requests/delete_section";
import update_section_tags from "../../../requests/update_section_tags";
import get_tags from "../../../requests/get_tags";
import { Tag } from "../../../types/Tag";
import Select from "react-select/creatable";
import { OptionTypeBase } from "react-select";

interface Props extends IProps {
  moduleID: number;
  onRefreshTags: VoidFunction;
}

interface Item {
  order: number;
  location: string;
  title: string;
  type: string;
}

interface IFilePrompt {
  promptType: string;
  contents?: string;
  file?: File;
  fileType: string;
  id: number;
  fileName?: string;
}

interface FetchedFile {
  id: number;
}

interface IContainer extends ModuleContainer {
  newContents?: string;
}

interface State {
  filePrompts: IFilePrompt[];
  expanded: boolean;
  loading: boolean;
  title: string;
  containers: IContainer[];
  confirmingFileDelete: boolean;
  fileToDelete: number;
  confirmingModuleDelete: boolean;
  loadingTags: boolean;
  tagOptions: Tag[];
  tags: OptionTypeBase[];
}

function isFilePrompt(container: IFilePrompt | IContainer): container is IFilePrompt {
  return (container as IFilePrompt).promptType !== undefined;
}

class Module extends React.Component<Props, State> {
  module = _.find(this.props.appState.project.modules, {
    id: this.props.moduleID,
  })!;

  state = {
    filePrompts: new Array<IFilePrompt>(),
    expanded: false,
    loading: false,
    title: this.module.title,
    containers: this.module.containers as IContainer[],
    confirmingFileDelete: false,
    fileToDelete: -1,
    confirmingModuleDelete: false,
    loadingTags: false,
    tagOptions: new Array<Tag>(),
    tags: this.module.tags.map((tag) => ({ label: tag.tag, value: tag.id })),
  };

  fetchFiles = async () => {
    const { project } = this.props.appState;
    const module = _.find(project.modules, { id: this.props.moduleID })!;
    try {
      await fetch_module_files({
        project_id: project.project_id,
        module_id: module.id,
      });
      this.setState({ loading: false });
    } catch (err) {
      this.props.dispatch(flashErrorMessage("An error occured while fetching files"));
    }
  };

  addPrompt = (fileType: string) => {
    const promptType = fileType === "code" ? "code" : fileType === "text" ? "text" : "file";
    const newState = {
      ...this.state,
      filePrompts: [...this.state.filePrompts, { fileType, id: this.state.filePrompts.length + 1, promptType }],
    };
    this.setState(newState);
    if (promptType === "text" || promptType === "code") this.submit(newState);
  };

  handlePromptChange = (id: number) => {
    return (contents?: string, file?: File) => {
      const changes = {
        filePrompts: this.state.filePrompts.map((prompt) => {
          if (prompt.id === id) {
            console.log(`prompt match: ${id}`);
            return { ...prompt, file: file, contents: contents };
          }
          return prompt;
        }),
      };
      const newState = { ...this.state, ...changes };
      this.setState(newState);
      this.submit(newState);
    };
  };

  refresh = async () => {
    const { project } = this.props.appState;
    const module = await get_module({
      project_id: project.project_id,
      module_id: this.props.moduleID,
    });
    console.log(JSON.stringify(module));
    this.setState({
      title: module.title,
      containers: module.containers,
      filePrompts: [],
      fileToDelete: -1,
      confirmingFileDelete: false,
      tags: module.tags.map((tag: any) => ({ label: tag.tag, value: tag.id })),
    });
  };

  onDelete = async () => {
    const { project } = this.props.appState;
    try {
      await delete_section({
        section_id: this.props.moduleID,
        project_id: project.project_id,
      });
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(flashErrorMessage("failed to delete section"));
    }
  };

  onDeleteFile = async (container_id: number) => {
    await delete_container({
      container_id,
      project_id: this.props.appState.project.project_id,
    });
    this.refresh();
  };

  getFileType = (type: string) => {
    switch (type.toLowerCase()) {
      case "text":
        return 1;
      case "code":
        return 6;
      case "image":
        return 2;
      case "pdf":
        return 3;
      case "stl":
        return 4;
      case "video":
        return 5;
      default:
        return 1;
    }
  };

  submit = async (newState?: State) => {
    const { project } = this.props.appState;
    this.setState({ loading: true });

    const state = newState !== undefined ? newState : this.state;
    const body: { [key: string]: any } = {
      module_id: this.props.moduleID,
      project_id: project.project_id,
    };

    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));

    console.log(`FILE PROMPTS:`, state.filePrompts);

    const filePrompts = state.filePrompts
      .filter(
        (fp) => fp.promptType === "text" || fp.promptType === "code" || (fp.file !== undefined && fp.file !== null)
      )
      .map((fp) =>
        fp.promptType === "text" || fp.promptType === "code"
          ? new File([fp.contents || ""], `${uniqid()}.${fp.promptType === "code" ? "code" : "txt"}`)
          : fp.file
      );
    filePrompts.forEach((file) => formData.append("files[]", file!));

    console.log(filePrompts);

    const textContainers = state.containers.filter((container) => container.newContents !== undefined);

    const textFiles = textContainers.map(
      (container) => new File([container.newContents || ""], path.basename(container.content_loc) || uniqid())
    );
    console.log(textFiles);

    textFiles.forEach((file) => formData.append("files[]", file || ""));

    console.log(
      "video prompts: ",
      this.state.filePrompts.filter((fp) => fp.fileType === "video/*")
    );

    try {
      const moduleNameResponse = await update_module({
        section_id: this.props.moduleID,
        title: state.title,
        objective: "",
        user_id: getCurrentUserID(),
        project_id: project.project_id,
      });
      const updateResponse = await update_module_files(formData);
      const createResponse = await Promise.all([
        ...filePrompts.map(async (file) => {
          const loc = `/${project.project_id}/${this.props.moduleID}/${file?.name || uniqueId()}`;
          const createResponse = await create_container({
            module_id: this.props.moduleID,
            project_id: project.project_id,
            user_id: getCurrentUserID(),
            location: loc,
            taglist: "",
            type: this.getFileType(path.extname(file?.name || "")),
          });
          console.log(`CREATE RESPONSE: ${createResponse}`);
          return createResponse;
        }),
        ...this.state.filePrompts
          .filter((fp) => fp.fileType === "video/*")
          .map(async (fp) => {
            console.log("video/*");
            if (fp.contents !== undefined && fp.contents !== null) {
              const createResponse = await create_container({
                module_id: this.props.moduleID,
                project_id: project.project_id,
                user_id: getCurrentUserID(),
                location: fp.contents || "",
                taglist: "",
                type: this.getFileType("video"),
              });
              return createResponse;
            }
          }),
      ]);
      const orderResponse = await update_container_order({
        project_id: project.project_id,
        module_id: this.props.moduleID,
        order_list: state.containers.map((container) => container.id).join(","),
      });
      this.setState({ loading: false, filePrompts: [] });
      this.refresh();
    } catch (err) {
      this.setState({ loading: false });
      this.props.dispatch(flashErrorMessage(`${err.message}`));
    }
  };

  async componentDidMount() {
    const tagOptions = await get_tags(0);
    this.setState({ loadingTags: false, tagOptions });
  }

  // componentDidUpdate = (prevProps: Props, prevState: State) => {
  //   if (
  //     !_.isEqual(prevState.title, this.state.title) ||
  //     !_.isEqual(prevState.filePrompts, this.state.filePrompts) ||
  //     !_.isEqual(prevState.containers, this.state.containers)
  //   ) {
  //     this.submit();
  //   }
  // };

  reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result: DropResult) => {
    const { containers } = this.state;
    if (!result.destination) {
      return;
    }
    const newState = {
      ...this.state,
      containers: this.reorder(containers, result.source.index, result.destination.index),
    };
    this.setState(newState);
    this.submit(newState);
  };

  updateTags = async () => {
    const { project_id } = this.props.appState.project;
    try {
      await update_section_tags({
        section_id: this.props.moduleID,
        project_id,
        user_id: getCurrentUserID(),
        taglist: this.state.tags.map((tag) => tag.label).join(","),
      });
      this.props.onRefreshTags();
    } catch (err) {
      this.props.dispatch(flashErrorMessage("failed to update tags"));
    }
  };

  toggleModuleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
    if (!this.state.expanded) {
      this.refresh();
    }
  };

  render() {
    const { project } = this.props.appState;
    const module = _.find(project.modules, { id: this.props.moduleID })!;
    const MAX_TAGS = 5;
    return (
      <div className={`wib-module wib-module-${this.state.expanded ? "expanded" : "collapsed"}`}>
        <WibConfirmModal
          isOpen={this.state.confirmingFileDelete}
          onCancel={() => this.setState({ confirmingFileDelete: false })}
          onConfirm={() => this.onDeleteFile(this.state.fileToDelete)}
        />
        <WibConfirmModal
          isOpen={this.state.confirmingModuleDelete}
          onCancel={() => this.setState({ confirmingModuleDelete: false })}
          onConfirm={() => this.onDelete()}
        />
        {/* {this.state.loading && <div>loading...</div>} */}
        <div
          className={`process-row-container process-row-container-${this.state.expanded ? "expanded" : "collapsed"}`}
        >
          <IconButton
            onClick={() => this.toggleModuleExpand()}
            name={`process-expand-chevron-${this.state.expanded ? "expanded" : "collapsed"}`}
          />
          <div className={`process-row-content`} onClick={() => this.toggleModuleExpand()}>
            <div className={`wib-process-title-text-container`}>
              <div
                contentEditable
                onClick={(e) => e.stopPropagation()}
                onKeyPress={(e) => e.which === 13 && e.preventDefault()}
                onBlur={(e) => {
                  const newState = { ...this.state, title: e.target.innerText };
                  this.setState(newState);
                  this.submit(newState);
                }}
                className={`wib-process-title-text`}
              >
                {module.title}
              </div>
            </div>
            <div onClick={(e) => e.stopPropagation()} className="tags-wrapper">
              <Select
                defaultValue={this.state.tags}
                value={this.state.tags}
                isMulti
                isDisabled={this.state.loadingTags}
                creatable
                onBlur={this.updateTags}
                options={this.state.tagOptions.map((tag) => ({
                  label: tag.tag,
                  value: tag.id,
                }))}
                onChange={(v: any, x: any) => {
                  console.log(v, x);
                  let tags = v;
                  if (v === null) {
                    tags = [];
                  }
                  tags.length <= MAX_TAGS &&
                    this.setState({
                      tags: tags
                        // .filter((tag: any) => {
                        //   const match = tag.label.match(/^[\w.\s]+\S$/);
                        //   console.log(match);
                        //   return match !== null;
                        // })
                        .map((tag: { label: string; value: any }) => ({
                          label: tag.label.replace(/[!@#$%^&*(),.?":{}|<>]/g, ""),
                          // .replace(" ", "")
                          // .replace("#", "")
                          // .replace("@", "")
                          // .trimStart(),
                          value: tag.value,
                        }))
                        .filter((tag: any) => tag.label !== ""),
                    });
                }}
              />

              <div className="tag-max-message">
                {`${MAX_TAGS - this.state.tags.length} tag${this.state.tags.length !== MAX_TAGS - 1 ? "s" : ""} left`}
              </div>
            </div>
            <div className="wib-actions-container wib-actions-container-process">
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ confirmingModuleDelete: true });
                }}
              />
            </div>
          </div>
          <div
            className="wib-steps-containter"
            style={{
              display: this.state.expanded ? "block" : "none",
            }}
          >
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable-1">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {this.state.containers.map((container, index) => {
                      return (
                        <Draggable key={container.id} draggableId={`${container.id}`} index={index}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <img src="" className="wib-drag-handle" {...provided.dragHandleProps} />

                              <Container
                                key={`${container.id}-${container.content_loc}`}
                                onContentChange={(newContents) => {
                                  const newState = {
                                    ...this.state,
                                    containers: this.state.containers.map((c) => {
                                      if (c.id === container.id) {
                                        console.log(`new contents: ${newContents}`);
                                        return {
                                          ...c,
                                          newContents,
                                        };
                                      }
                                      return c;
                                    }),
                                  };
                                  this.setState(newState);
                                  this.submit(newState);
                                }}
                                container={container}
                                onDelete={() =>
                                  this.setState({
                                    confirmingFileDelete: true,
                                    fileToDelete: container.id,
                                  })
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {this.state.filePrompts.map(
              (prompt) =>
                prompt.promptType !== "code" &&
                prompt.promptType !== "text" && (
                  <FilePrompt
                    onRemove={() => {
                      this.setState({
                        filePrompts: this.state.filePrompts.filter((pr) => pr.id !== prompt.id),
                      });
                    }}
                    type={prompt.fileType.toLowerCase()}
                    onChange={this.handlePromptChange(prompt.id)}
                  />
                )
            )}

            <div className="wib-button-container wib-button-container-module-actions">
              <button
                onClick={() => this.addPrompt("text")}
                className="wib-button wib-button-module-action wib-button-addtext"
              >
                Add Text
              </button>
              <button
                onClick={() => this.addPrompt("code")}
                className="wib-button wib-button-module-action wib-button-addcode"
              >
                Add Code
              </button>
              <button
                onClick={() => this.addPrompt("image/*")}
                className="wib-button wib-button-module-action wib-button-addimage"
              >
                Add Image
              </button>
              <button
                onClick={() => this.addPrompt("video/*")}
                className="wib-button wib-button-module-action wib-button-addvideo"
              >
                Add Video
              </button>
              <button
                onClick={() => this.addPrompt(".stl")}
                className="wib-button wib-button-module-action wib-button-addstl"
              >
                Add STL File
              </button>
              <button
                onClick={() => this.addPrompt(".pdf")}
                className="wib-button wib-button-module-action wib-button-addpdf"
              >
                Add PDF File
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(Module);
