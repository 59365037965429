import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector from "../../common_connector";
import _ from "lodash";
import update_collabtype from "../../requests/update_collabtype";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import { fetch_project } from "../../ducks/project";
import Text from "../Atoms/Text";
import { initial_user } from "../../types/User";

interface Props {
  selectedUser: number;
  appState: AppState;
  dispatch: ThunkDispatch<AppState, any, any>;
  onSuccess?: VoidFunction;
}

type Values = {
  type: { value: number; label: string };
  removeConfirm: boolean;
};

interface State {
  loading: boolean;
}

class EditCollab extends React.Component<Props, State> {
  state = { loading: false };

  handleSubmit = async (values: Values) => {
    try {
      this.setState({ loading: true });
      await update_collabtype({
        user_id: this.props.selectedUser,
        collab_type: values.type.value,
        project_id: this.props.appState.project.project_id,
      });
      this.props.dispatch(
        fetch_project(this.props.appState.project.project_id)
      );
      this.setState({ loading: false });
      this.props.dispatch(
        flashMessage("Successfully updated contributor type")
      );
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;
    const selected_user =
      _.find(project.users, {
        user_id: this.props.selectedUser,
      }) || initial_user;
    const opts = [
      { value: 1, label: "Primary" },
      { value: 2, label: "Contributor" },
    ];
    const initialValues = {
      type: _.find(opts, { value: selected_user.collab_type }) || opts[0],
      removeConfirm: false,
    };
    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ values, handleChange, setFieldValue }) => (
          <Form className="wf wf-form wf-collabs" name="collabs">
            <h2>Edit Collaborator</h2>
            <Text name="edit-collab-header">{`${selected_user.firstname} ${selected_user.lastname}`}</Text>
            <Select
              value={values.type}
              onChange={(val) => setFieldValue("type", val)}
              options={opts}
            />
            <div
              style={{
                display: values.type.value === 3 ? "inline-block" : "none",
              }}
            >
              <FormField
                type="checkbox"
                value={values.removeConfirm}
                formName="collabs"
                labelText="Confirm Remove?"
                extraInfo="Would you like to remove this user from the project?"
                itemName="removeConfirm"
                onChange={handleChange}
              />
            </div>
            {this.state.loading && "loading"}
            <FormSubmit
              disabled={
                values.type.value === 3 &&
                !values.removeConfirm &&
                !this.state.loading
              }
              formName="collabs"
              labelText="Submit"
              extraInfo=""
              itemName="submit"
              value="submit"
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(EditCollab);
