import React, { Component } from "react";
import WibModal from "./WibModal";
import { Props } from "./WIBMediaModal";
import { STLViewer, OBJViewer } from "react-stl-obj-viewer";
import path from "path";
import STLView from "./STLView";

export default class STLModal extends Component<Props, {}> {
  render() {
    return (
      <WibModal title={this.props.docName} {...this.props}>
        <STLView docName={this.props.docName} docPath={this.props.docPath} />
      </WibModal>
    );
  }
}
