import React from "react";
import connector, { IProps } from "../../../common_connector";
import CollectionItemTitle from "../../Atoms/CollectionItemTitle";
import ProcessForm from "../../Forms/Process";
import Process from "./Process";
import AddButton from "../../Atoms/AddButton";
import UserContext from "../../../UserContext";
import { flashErrorMessage, flashSuccessMessage } from "redux-flash";
import { fetch_project } from "../../../ducks/project";
import delete_process from "../../../requests/delete_process";
import WibConfirmModal from "../../Atoms/WibConfirmModal";
import WibModal from "../../Atoms/WibModal";

interface Props extends IProps {
  canEdit: () => boolean;
}

interface State {
  editing: boolean;
  creating: boolean;
  selectedProcess: number;
  confirming: boolean;
  toDelete: number;
}

class Processes extends React.Component<Props, State> {
  state = {
    editing: false,
    creating: false,
    selectedProcess: -1,
    confirming: false,
    toDelete: -1,
  };

  deleteProcess = async () => {
    const { project } = this.props.appState;
    try {
      await delete_process({
        project_id: project.project_id,
        process_id: this.state.toDelete,
      });
      this.setState({ confirming: false });
      this.props.dispatch(flashSuccessMessage("Successfully deleted process"));
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(
        flashErrorMessage("An error occured while deleting the process")
      );
    }
  };

  closePanels = () => this.setState({ editing: false, creating: false });

  render() {
    const { project } = this.props.appState;
    return (
      <>
        <WibConfirmModal
          onCancel={() => this.setState({ confirming: false })}
          onConfirm={this.deleteProcess}
          isOpen={this.state.confirming}
        />
        <WibModal
          title={this.state.editing ? "Edit Process" : "Create Process"}
          isOpen={this.state.editing || this.state.creating}
          onDismiss={this.closePanels}
        >
          <ProcessForm
            editing={this.state.editing}
            onSuccess={this.closePanels}
            selectedProcess={this.state.selectedProcess}
          />
        </WibModal>
        <div className="processes">
          <CollectionItemTitle name="processes" text="Processes" />
          {project.processes.map((process) => (
            <Process
              onDelete={() =>
                this.setState({ confirming: true, toDelete: process.id })
              }
              key={process.id}
              process={process}
              onClick={() => {
                if (this.props.canEdit()) {
                  this.setState({
                    editing: true,
                    selectedProcess: process.id,
                  });
                }
              }}
            />
          ))}
          <AddButton
            onClick={() => {
              if (this.props.canEdit()) {
                this.setState({ creating: true });
              }
            }}
            text="Add Process"
          />
        </div>
      </>
    );
  }
}

export default connector(Processes);
