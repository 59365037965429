import base_url from "./base_url";
import request from "./request_helper";

export interface Args {
  project_title: string;
  purpose: string;
  proj_desc: string;
  category: number;
  subcategory: number;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "post",
    baseURL: base_url,
    url: `projects`,
    data: args,
  });
}
