import base_url from "./base_url";
import request from "./request_helper";

export default async function execute(project_id: number): Promise<any> {
  return await request({
    method: "GET",
    baseURL: base_url,
    url: `/projects/${project_id}/threads`,
  });
}
