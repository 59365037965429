import React from "react";
import IThread from "../../types/Thread";
import Thread from "./Thread";
import get_threads from "../../requests/get_project_threads";
import get_thread from "../../requests/get_thread";
import CollectionItemTitle from "../Atoms/CollectionItemTitle";
import AddButton from "../Atoms/AddButton";

interface Props {
  project_id: number;
}

interface State {
  loading: boolean;
  creating: boolean;
  threads: IThread[];
  hasError: boolean;
  error: string;
}

export default class Threads extends React.Component<Props, State> {
  state = {
    loading: true,
    creating: false,
    threads: new Array<IThread>(),
    hasError: false,
    error: "",
  };

  fetchThreads = async () => {
    try {
      const threads = await get_threads(this.props.project_id);
      this.setState({ threads, loading: false, creating: false });
    } catch (err) {
      this.setState({
        hasError: true,
        error: "an unexpected error occured while fetching threads",
      });
    }
  };

  refreshThread = async (thread_id: number) => {
    try {
      const thread = await get_thread({ thread_id });
      this.setState({ threads: this.state.threads.map((th) => (th.id === thread.id ? thread : th)) });
    } catch (err) {
      this.setState({
        hasError: true,
        error: "an unexpected error occured while refreshing the thread",
      });
    }
  };

  async componentDidMount() {
    this.fetchThreads();
  }

  render() {
    return (
      <div className="wib-threads">
        <CollectionItemTitle name="threads" text="Discussion" />
        {this.state.loading && <div>loading...</div>}
        {this.state.hasError && <div>{this.state.error}</div>}
        {this.state.threads.map((thread) => (
          <Thread
            refreshThread={this.refreshThread}
            project_id={this.props.project_id}
            creating={false}
            thread={thread}
          />
        ))}
        {!this.state.creating && (
          <AddButton onClick={() => this.setState({ creating: true })} text="Create Thread" />
          // <button className="wib-button wib-button-create-thread" onClick={() => this.setState({ creating: true })}>
          //   create thread
          // </button>
        )}
        <div className="wib-thread-create">
          {this.state.creating && (
            <Thread
              refreshThread={this.refreshThread}
              onCancelCreate={() => this.setState({ creating: false })}
              handlePost={this.fetchThreads}
              project_id={this.props.project_id}
              creating
            />
          )}
          {/* {this.state.creating && (
            <button className="wib-thread-cancel" onClick={() => this.setState({ creating: false })}>
              cancel
            </button>
          )} */}
        </div>
      </div>
    );
  }
}
