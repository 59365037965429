import React, { ReactElement } from "react";
import ProjectStub from "../../types/ProjectStub";
import Text from "../Atoms/Text";
import { Link } from "react-router-dom";
import ProjectStubRender from "../ProjectStubRender";

interface Props {
  projects: ProjectStub[];
}

export default function Projects({ projects }: Props): ReactElement {
  return (
    <div className="profile-projects project-sample-container project-list-projects plp wib-collection">
      {projects.map((project) => (
        <ProjectStubRender project={project} />
      ))}
    </div>
    //     <div key={`${project.title}-${project.id}`} className="project-sample">
    //       <div className="profile-project-title project-sample-title">
    //         <Text name="project-title-link">
    //           <Link to={`/projects/${project.id}`} target="_blank">
    //             {project.title}
    //           </Link>
    //         </Text>
    //       </div>
    //       <div className="profile-project-description project-sample-description">
    //         NEED DESCRIPTION
    //       </div>
    //       <div className="profile-project-category project-sample-category">
    //         {project.category}
    //       </div>
    //       <div className="profile-project-subcategory project-sample-subcategory">
    //         {project.subcategory}
    //       </div>
    //       <div className="profile-project-collaborators project-sample-collaborators">
    //         collaborators: NEED COLLABS COUNT
    //       </div>
    //     </div>
    //   ))}
    // </div>
  );
}
