export default interface FullUser {
  id: number;
  primary_email: string;
  username: string;
  firstname: string;
  lastname: string;
  user_temp: number;
  user_enabled: number;
  email_referrer: number;
  user_type: number;
  user_active_project: string;
  user_status_string: string;
  created_d: string;
  updated_d: string;
}

export const initial_user = {
  id: -1,
  primary_email: "",
  username: "",
  firstname: "",
  lastname: "",
  user_temp: -1,
  user_enabled: -1,
  email_referrer: -1,
  user_type: -1,
  user_active_project: "",
  user_status_string: "",
  created_d: "",
  updated_d: "",
};
