import React from "react";
import WibModal from "./WibModal";
import Text from "./Text";

interface Props {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  title?: string;
  details?: string;
  isOpen: boolean;
}

export default function WibConfirmModal(props: Props) {
  return (
    <WibModal
      additionalClass="confirm-modal"
      isOpen={props.isOpen}
      title={props.title || "Please confirm"}
      onDismiss={() => props.onCancel()}
    >
      <Text name="modal-details">
        {props.details ||
          "Are you sure you would like to delete? This action cannot be undone"}
      </Text>
      <div className="wib-buttons-container wib-buttons-container-modal">
        <button
          className="wib-button wib-button-submit"
          onClick={() => props.onCancel()}
        >
          Cancel
        </button>
        <button
          className="wib-button wib-button-delete"
          onClick={() => props.onConfirm()}
        >
          Confirm
        </button>
      </div>
    </WibModal>
  );
}
