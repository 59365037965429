import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select/creatable";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import { OptionTypeBase } from "react-select";
import * as Yup from "yup";
import create_user_rolefocus from "../../requests/create_user_rolefocus";
import getCurrentUserID from "../../getCurrentUserID";
import User from "../../types/User";
import { fetch_users } from "../../ducks/users";
import { fetch_project } from "../../ducks/project";
import { fetch_role_options } from "../../ducks/roleOptions";
import get_detail_options from "../../requests/get_detail_options";

interface Props extends IProps {
  onSuccess?: VoidFunction;
  isRole: boolean;
}

type Values = {
  user?: OptionTypeBase;
  role?: OptionTypeBase;
  detail?: OptionTypeBase;
  detailOptions?: OptionTypeBase[];
};

interface State {
  loading: boolean;
  users: User[];
}

class AddCollabRole extends React.Component<Props, State> {
  state = {
    loading: false,
    users: new Array<User>(),
  };

  componentDidMount() {
    this.props.dispatch(fetch_users());
    this.props.dispatch(fetch_role_options(this.props.appState.project.project_id));
  }

  handleSubmit = async (values: Values) => {
    const { project } = this.props.appState;
    try {
      this.setState({ loading: true });

      await create_user_rolefocus({
        project_id: project.project_id,
        user_id: getCurrentUserID(),
        role: values.role?.label || "",
        focus: values.detail?.label || "",
        email: values.user?.email || "",
      });

      this.setState({ loading: false });
      this.props.dispatch(flashMessage(`Success`));
      this.props.dispatch(fetch_project(project.project_id));
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(flashErrorMessage(`${error.message} (code: ${error.code})`));
    }
  };

  _fetchDetailOptions = async (roleName: string, setFieldValue: any) => {
    const { project_id } = this.props.appState.project;
    const detail_results = await get_detail_options({
      project_id,
      role_name: roleName,
    });
    const focusMapper = (result: any) => ({
      label: result.focus,
      value: result.focus_id,
    });
    const projectDetails = _.filter(detail_results, {
      isCurrentProjectRole: 0,
    }).map(focusMapper);
    const siteDetails = _.filter(detail_results, {
      isCurrentProjectRole: 1,
    }).map(focusMapper);
    const options = [
      { label: "project details", options: projectDetails },
      { label: "sitewide details", options: siteDetails },
    ];
    setFieldValue("detailOptions", options);
  };

  render() {
    const { project, roleOptions } = this.props.appState;
    const { isRole } = this.props;

    const initialValues = {
      user: undefined,
      role: undefined,
      detail: undefined,
      detailOptions: undefined,
    };
    const validationSchema = Yup.object().shape({
      user: Yup.object()
        .shape({ email: Yup.string().email("not a valid email address") })
        .required("required"),
      role: isRole ? Yup.object().required() : Yup.object().nullable().notRequired(),
      detail: Yup.object().notRequired(),
    });
    const formName = "addcollab";

    if (this.props.appState.loading) {
      return <div>loading...</div>;
    }

    const roleMapper = (role: any) => ({
      label: role.role,
      value: role.role_id,
      __isNew__: false,
    });

    const projectRoles = _.filter(roleOptions, { isCurrentProj: 1 }).map(roleMapper);
    const siteRoles = _.filter(roleOptions, { isCurrentProj: 0 }).map(roleMapper);
    const roleOpts = [
      { label: "project roles", options: projectRoles },
      { label: "sitewide roles", options: siteRoles },
    ];

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validationSchema={validationSchema}>
        {({ values, handleChange, setFieldValue, handleBlur, touched, errors, setFieldTouched }) => (
          <Form className={`wf wf-form wf-${formName}`} name={formName}>
            <FormField
              labelText="User"
              formName={formName}
              itemName="user"
              value={values.user}
              customInput={() => (
                <Select
                  onCreateOption={(v) => setFieldValue("user", { label: v, email: v })}
                  formatCreateLabel={(input) => (
                    <div className="invite-label">
                      <span className="invite-text">Invite</span> {input}
                    </div>
                  )}
                  onBlur={() => setFieldTouched("user")}
                  options={this.props.appState.users.map((user) => ({
                    label: `${user.firstname} ${user.lastname} (${user.primary_email})`,
                    email: user.primary_email,
                    value: user.id,
                    __isNew__: false,
                  }))}
                  onChange={(v) => setFieldValue("user", v)}
                  value={values.user}
                />
              )}
            />
            {errors.user && touched.user && (
              <div className="wib-error">
                {(errors.user as any).email ? "please enter an email address" : "required"}
              </div>
            )}
            <FormField
              labelText={isRole ? "Role" : "Role (optional)"}
              formName={formName}
              itemName="role"
              value={values.role}
              customInput={() => (
                <Select
                  isClearable={!isRole}
                  onBlur={() => setFieldTouched("role")}
                  options={roleOpts}
                  onChange={async (v: any) => {
                    setFieldValue("role", v);
                    setFieldValue(
                      "detailOptions",
                      _.find(project.roles, {
                        role_id: v !== undefined && v !== null ? v.value : -1,
                      })?.foci.map((focus) => ({
                        label: focus.focus,
                        value: focus.focus_id,
                        __isNew__: false,
                      }))
                    );
                  }}
                  value={values.role}
                />
              )}
            />
            {errors.role && touched.role && <div className="wib-error">required</div>}
            <FormField
              labelText="Detail (optional)"
              formName={formName}
              itemName="detail"
              value={values.detail}
              customInput={() => (
                <Select
                  isClearable
                  onBlur={() => setFieldTouched("role")}
                  options={values.detailOptions}
                  onChange={(v) => setFieldValue("detail", v)}
                  value={values.detail}
                />
              )}
            />
            {errors.detail && touched.detail && <div className="wib-error">required</div>}
            <FormSubmit
              disabled={this.state.loading}
              formName={formName}
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(AddCollabRole);
