import React, { useState } from "react";
import ProjectStub from "../types/ProjectStub";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";

export default ({ project }: { project: ProjectStub }) => {
  const [clicked, setClicked] = useState(false);
  return clicked ? (
    <Redirect push to={`/projects/${project.id}`} />
  ) : (
    <div
      className="wib-plp-row-container wib-row-container item-row-container plp-row-container-collapsed container-collapsed"
      onClick={() => setClicked(true)}
      style={{ cursor: "pointer" }}
    >
      <div className="wib-plp-title-text-container wib-title-text-container title-text-container">
        <div className="wib-plp-title-text wib-title-text title-text">
          <Link to={`/projects/${project.id}`}>{project.title}</Link>
        </div>
      </div>
      <div
        className="wib-plp-expand-chevron-collapsed-container wib-expand-chevron-collapsed-container chevron-collapsed-container "
        style={{ cursor: "pointer" }}
      >
        <img
          src="/app/static/media/add.c5b0d45d.svg"
          alt="plp-expand-chevron-collapsed expand-chevron-collapsed"
        />
        <div className="wib-plp-expand-chevron-collapsed-text chevron-collapsed-text"></div>
      </div>
      <div className="plp-item-row-content item-row-content row-content">
        <div className="wib-plp-description-text-container wib-description-text-container description-text-container">
          <div className="wib-plp-description-text wib-description-text text-item">
            {project.proj_desc}
          </div>
        </div>
        {/* <div className="wib-plp-collabcount-metadata-key-text-container wib-plp-metadata-key-text-container metadata-key-text-container">
          <div className="wib-plp-collabcount-metadata-key-container wib-collabcount-metadata-key-container metadata-key-container">
            <div className="wib-plp-collabcount-metadata-key-text wib-collabcount-metadata-key-text metadata-key-text">
              collaborators:
            </div>
          </div>
          <div className="wib-plp-collabcount-metadata-value-container wib-collabcount-metadata-value-container metadata-value-container">
            <div className="wib-plp-collabcount-metadata-value-text wib-plp-metadata-key-value-text metadata-value-container">
              {project.collab_count}
            </div>
          </div>
        </div> */}

        <div className="wib-plp-collablinks-collection wib-collablinks-collection wib-collection">
          {_.take(project.users, 5).map((user) => (
            <div
              key={user.user_id}
              className="wib-plp-collablinks-item-row wib-collablinks-item-row wib-item-row"
            >
              <div
                className="wib-plp-collablink-collection-item-container wib-collection-item-container collecion-item-container"
                style={{ cursor: "pointer" }}
              >
                <div className="wib-plp-collablink-collection-item wib-collablink-item collection-item">
                  <Link
                    className="wib-plp-collablink wib-collablink collablink"
                    to={`/profile/${user.user_id}`}
                  >
                    {`${user.firstname} ${user.lastname}`}
                  </Link>
                </div>
              </div>
            </div>
          ))}
          {project.users.length > 4 && (
            <div className="wib-plp-collablinks-item-row wib-collablinks-item-row wib-item-row">
              <div
                className="wib-plp-collablink-collection-item-container wib-collection-item-container collecion-item-container"
                style={{ cursor: "pointer" }}
              >
                <div className="wib-plp-collablink-collection-item wib-collablink-item collection-item">
                  <div className="wib-plp-collablink wib-collablink collablink">
                    + {project.users.length - 4} More Collaborators
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="wib-add-container" style={{ cursor: "pointer" }}>
        <img src="/app/static/media/add.c5b0d45d.svg" alt="add" />
        <div className="wib-add-text">
          WE WILL HIDE THIS - BUT IN THE NAME OF UNIFORMITY, I THOUGHT, INCLUDE
        </div>
      </div>
    </div>
  );
};
