import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select/creatable";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import { OptionTypeBase } from "react-select";
import create_step from "../../requests/create_step";
import { fetch_project } from "../../ducks/project";
import * as Yup from "yup";

interface Props extends IProps {
  onSuccess?: VoidFunction;
  processID: number;
}

type Values = {
  title: string;
  action: string;
  tags: OptionTypeBase[];
};

interface State {
  loading: boolean;
}

class CreateStep extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    try {
      this.setState({ loading: true });
      const requestBody = {
        process_id: this.props.processID,
        project_id: this.props.appState.project.project_id,
        owner_id: _.sample(this.props.appState.project.users)!.user_id,
        role_id: _.find(this.props.appState.project.processes, {
          id: this.props.processID,
        })!.role_id,
        title: values.title,
        action: values.action,
        tags: values.tags.map((tag) => tag.value).join(","),
      };
      console.log(requestBody);
      await create_step(requestBody);
      this.setState({ loading: false });
      this.props.dispatch(flashMessage(`Successfully created step`));
      this.props.dispatch(
        fetch_project(this.props.appState.project.project_id)
      );
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;

    const initialValues = {
      title: "",
      action: "",
      tags: [],
    };

    const validationSchema = Yup.object().shape({
      title: Yup.string().required("required"),
    });

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form className="wf wf-form wf-createstep" name="createstep">
            <FormField
              onBlur={handleBlur}
              labelText="Title"
              formName="createstep"
              itemName="title"
              value={values.title}
              onChange={handleChange}
            />
            {errors.title && touched.title && (
              <div className="wib-error">{errors.title}</div>
            )}
            <FormField
              labelText="Description (Optional)"
              formName="createstep"
              itemName="action"
              value={values.action}
              onChange={handleChange}
              customInput={({ className, value }) => (
                <textarea
                  name="action"
                  className={className}
                  onChange={handleChange}
                  value={value}
                />
              )}
            />
            <FormField
              labelText="Tags"
              formName="createstep"
              itemName="tags"
              value={values.tags}
              customInput={() => (
                <Select
                  value={values.tags}
                  isMulti
                  onChange={(values) => setFieldValue("tags", values)}
                />
              )}
            />
            <FormSubmit
              disabled={this.state.loading}
              formName="createstep"
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading"}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(CreateStep);
