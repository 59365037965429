import BASE_URL from "./base_url";
import request from "./request_helper";

type Response = { Success: number };

export default async function execute(args: any): Promise<Response> {
  return request({
    method: "PUT",
    baseURL: BASE_URL,
    url: "projects",
    data: args,
  });
}
