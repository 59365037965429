import React from "react";
import { FileDropper } from "./FileDropper";
import DeleteIcon from "../../Atoms/DeleteIcon";
import FormField from "../../Atoms/FormField";

interface Props {
  type: string;
  onChange: (contents?: string, file?: File) => void;
  onRemove: VoidFunction;
}

export const FilePrompt = (props: Props) => {
  const switchType = () => {
    switch (props.type) {
      case "code":
        return (
          <div
            contentEditable
            onBlur={(e) => props.onChange(e.target.innerText)}
            className="codeinput"
          />
        );
      case "text":
        return (
          <pre>
            <div
              contentEditable
              onBlur={(e) => props.onChange(e.target.innerText)}
              className="textinput"
            />
          </pre>
        );
      case "video/*":
        return (
          <div className="wff-row wff-row-title">
            {/* <div
              className={`wff-extrainfo-wrap wff-extrainfo-wrap-module-video`}
            >
              <h5 className={`wff-extrainfo wff-extrainfo-module-video`}></h5>
            </div> */}
            <div className={`wff-wrap wff-wrap-text wff-wrap-module-video`}>
              <input
                placeholder="https://somedomain.com/myvideo.ext"
                onBlur={(e) =>
                  e.target.value !== "" &&
                  props.onChange(e.target.value, undefined)
                }
                className={`wff wff-text wff-module-video`}
              />
            </div>
          </div>
        );
      default:
        console.log("default");
        return (
          <FileDropper
            fileType={props.type}
            onDrop={(files) => props.onChange(undefined, files[0])}
          />
        );
    }
  };

  return (
    <div className="fileprompt">
      {switchType()}
      <DeleteIcon onClick={() => props.onRemove()} />
    </div>
  );
};
