import React, { PropsWithChildren } from "react";

export default (
  props: PropsWithChildren<{
    containerClass?: string;
    textClass?: string;
    name?: string;
  }>
) => (
  <div
    className={
      props.name !== undefined
        ? `wib-${props.name}-text-container`
        : props.containerClass || "wib-text-container"
    }
  >
    <div
      className={
        props.name !== undefined
          ? `wib-${props.name}-text`
          : props.textClass || "wib-text"
      }
    >
      {props.children}
    </div>
  </div>
);
