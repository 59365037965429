export default interface User {
  user_id: number;
  username: string;
  firstname: string;
  lastname: string;
  collab_type: number;
  primary_email: string;
}

export const initial_user = {
  user_id: -1,
  username: "",
  firstname: "",
  lastname: "",
  collab_type: -1,
  primary_email: "",
};
