import Loading, { initial_loading } from "../types/Loading";
import { AnyAction } from "redux";

export const SET_LOADING = "wib/loading/SET_LOADING";

export type SetLoadingAction = { type: string; loading: boolean };

export default function reducer(
  state: Loading = initial_loading,
  action: AnyAction
) {
  switch (action.type) {
    case SET_LOADING:
      const set_loading_action = action as SetLoadingAction;
      return set_loading_action.loading;
    default:
      return state;
  }
}

export function set_loading(loading: boolean): SetLoadingAction {
  return { type: SET_LOADING, loading };
}
