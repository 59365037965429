import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import update_focusname from "../../requests/update_focusname";
import { fetch_project } from "../../ducks/project";
import * as Yup from "yup";

interface Props extends IProps {
  onSuccess?: VoidFunction;
  selectedFocus: number;
  selectedRole: number;
}

type Values = {
  detailName: string;
};

interface State {
  loading: boolean;
}

class EditFocus extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    const { project } = this.props.appState;
    const role = _.find(project.roles, { role_id: this.props.selectedRole })!;
    const focus = _.find(role.foci, { focus_id: this.props.selectedFocus })!;
    try {
      this.setState({ loading: true });
      await update_focusname({
        id: focus.focus_id,
        focus_name: values.detailName,
        project_id: project.project_id,
      });

      this.setState({ loading: false });
      this.props.dispatch(flashMessage(`Successfully Edited Detail`));
      this.props.dispatch(fetch_project(project.project_id));
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;

    const formName = "editfocus";

    const role = _.find(project.roles, { role_id: this.props.selectedRole })!;
    const focus = _.find(role.foci, { focus_id: this.props.selectedFocus })!;

    const initialValues = {
      detailName: focus.focus,
    };

    const validationSchema = Yup.object().shape({
      detailName: Yup.string().required("required"),
    });

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ values, handleChange, setFieldValue }) => (
          <Form className={`wf wf-form wf-${formName}`} name={formName}>
            <FormField
              labelText="Detail Name"
              formName={formName}
              itemName="detailName"
              value={values.detailName}
              onChange={handleChange}
            />

            <FormSubmit
              disabled={this.state.loading}
              formName={formName}
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading"}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(EditFocus);
