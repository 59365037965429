import React, { PropsWithChildren } from "react";
import Text from "../Atoms/Text";

interface Props {
  name: string;
  onClick?: VoidFunction;
}

export default (props: PropsWithChildren<Props>) => (
  <div
    className="wib-titlebar"
    onClick={() => props.onClick !== undefined && props.onClick()}
  >
    <Text
      containerClass="wib-titlebar-text-container"
      textClass="wib-titlebar-text"
    >
      {props.name}
    </Text>
    {props.children}
  </div>
);
