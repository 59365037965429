import BASE_URL from "./base_url";
import request from "./request_helper";
import FormData from "form-data";

export default async function execute(args: FormData): Promise<any> {
  return await request({
    method: "PUT",
    baseURL: BASE_URL,
    url: `/projects/step`,
    data: args,
  });
}
