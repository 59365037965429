import ProjectStub from "../types/ProjectStub";
import { AnyAction, Dispatch } from "redux";
import { set_loading } from "./loading";
import get_projects from "../requests/get_projects";

export const FETCH_PROJECTS = "wib/projects/FETCH_PROJECTS";
export const RECEIVE_PROJECTS = "wib/projects/RECEIVE_PROJECTS";

export type FetchProjectsAction = { type: string };
export type ReceiveProjectsAction = { type: string; projects: ProjectStub[] };

export default function reducer(state: ProjectStub[] = [], action: AnyAction) {
  switch (action.type) {
    case RECEIVE_PROJECTS:
      const receive_projects_action = action as ReceiveProjectsAction;
      return receive_projects_action.projects;
    default:
      return state;
  }
}

export function fetch_projects() {
  return async function (dispatch: Dispatch) {
    dispatch(set_loading(true));
    const projects = await get_projects();
    dispatch(set_loading(false));
    dispatch(receive_projects(projects));
  };
}

export function receive_projects(projects: ProjectStub[]) {
  return { type: RECEIVE_PROJECTS, projects };
}
