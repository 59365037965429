import { AnyAction, Dispatch } from "redux";
import { set_loading } from "./loading";
import get_roles from "../requests/get_roles";
import RoleOption from "../types/RoleOption";

export const FETCH_ROLE_OPTIONS = "wib/projects/FETCH_ROLE_OPTIONS";
export const RECEIVE_ROLE_OPTIONS = "wib/projects/RECEIVE_ROLE_OPTIONS";

export type FetchRoleOptionsAction = { type: string };
export type ReceiveRoleOptionsAction = { type: string; options: RoleOption[] };

export default function reducer(state: RoleOption[] = [], action: AnyAction) {
  switch (action.type) {
    case RECEIVE_ROLE_OPTIONS:
      const receive_role_options_action = action as ReceiveRoleOptionsAction;
      return receive_role_options_action.options;
    default:
      return state;
  }
}

export function fetch_role_options(project_id: number) {
  return async function (dispatch: Dispatch) {
    dispatch(set_loading(true));
    const roles = await get_roles({ project_id });
    dispatch(set_loading(false));
    dispatch(receive_role_options(roles));
  };
}

export function receive_role_options(
  options: RoleOption[]
): ReceiveRoleOptionsAction {
  return { type: RECEIVE_ROLE_OPTIONS, options };
}
