import BASE_URL from "./base_url";
import Project from "../types/Project";
import request from "./request_helper";

export interface Args {
  project_id: number;
}

export default async function execute(args: Args): Promise<Project> {
  return await request({
    method: "GET",
    baseURL: BASE_URL,
    url: `projects/${args.project_id}/render`,
  });
}
