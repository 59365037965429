import BASE_URL from "./base_url";
import request from "./request_helper";

export interface Response {
  success: boolean;
}

export interface Args {
  id: number;
  label: string;
  description: string;
  username: string;
  password: string;
  project_id: number;
  url: string;
}

export default async function execute(args: Args): Promise<Response> {
  return request({
    method: "put",
    baseURL: BASE_URL,
    url: "projects/integration",
    data: args,
  });
}
