import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import querystring from "querystring";
import Axios from "axios";
import base_url from "../../requests/base_url";
import { Redirect } from "react-router-dom";
import { clear as lsClear } from "local-storage";

interface Props extends IProps {
  onSuccess?: VoidFunction;
}

type Values = {
  password: string;
  passwordConfirm: string;
};

interface State {
  loading: boolean;
  success: boolean;
}

class PasswordReset extends React.Component<Props, State> {
  state = {
    loading: false,
    success: false,
  };

  handleSubmit = async (values: Values) => {
    try {
      this.setState({ loading: true });
      const { search } = window.location;
      const searchParams = querystring.parse(search.replace("?", ""));
      const token = searchParams.token || "";
      await Axios.patch(
        "/users/password",
        { password: values.password },
        {
          baseURL: base_url,
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      this.setState({ loading: false, success: true });
      lsClear();
      this.props.dispatch(flashMessage(`Successfully reset your password`));
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;

    const initialValues = { password: "", passwordConfirm: "" };
    const formName = "resetpassword";

    const validate = (values: Values) => {
      const errors: any = {};
      const passwordErr =
        values.password === ""
          ? "Please enter a password"
          : values.password !== values.passwordConfirm
          ? "Passwords do not match"
          : undefined;
      if (passwordErr !== undefined) {
        errors.password = passwordErr;
      }
      return errors;
    };

    return (
      <>
        {this.state.success && <Redirect to="/?login=1" />}
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validate={validate}
        >
          {({ values, handleBlur, handleChange, setFieldValue, errors }) => (
            <Form className={`wf wf-form wf-${formName}`} name={formName}>
              <FormField
                itemName="password"
                labelText="Password"
                formName={formName}
                value={values.password}
                onChange={handleChange}
                type="password"
                onBlur={handleBlur}
              />
              <FormField
                itemName="passwordConfirm"
                labelText="Confirm Password"
                formName={formName}
                value={values.passwordConfirm}
                onChange={handleChange}
                type="password"
                onBlur={handleBlur}
              />
              {errors.password && (
                <div className="wf-error">{errors.password}</div>
              )}

              <FormSubmit
                disabled={this.state.loading && errors.password !== undefined}
                formName={formName}
                labelText="Submit"
                itemName="submit"
                value="submit"
              />
              {this.state.loading && "loading"}
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default connector(PasswordReset);
