import { connect } from "react-redux";
import AppState from "./types/AppState";
import { ThunkDispatch } from "redux-thunk";

export interface IProps {
  appState: AppState;
  dispatch: ThunkDispatch<AppState, any, any>;
}

const mapStateToProps = (appState: AppState) => ({ appState });
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, any, any>) => ({
  dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps);
