import ProfileCollab from "./ProfileCollab";
import ProfileRole from "./ProfileRole";
import ProjectStub from "./ProjectStub";
import { Tag } from "./Tag";

export default interface Profile {
  id: number;
  primary_email: string;
  username: string;
  firstname: string;
  lastname: string;
  user_temp: number;
  user_enabled: number;
  email_referrer: number;
  user_type: number;
  user_active_project: string;
  activity_current_title: string;
  user_current_activity: string;
  user_status: string;
  created_d: string;
  updated_d: string;
  collabs: ProfileCollab[];
  profile_roles: ProfileRole[];
  projects: ProjectStub[];
  addtl_info: string;
  tags: Tag[];
  profile_photo: string | null;
}

export const initial_profie: Profile = {
  id: -1,
  primary_email: "",
  username: "",
  firstname: "",
  lastname: "",
  user_temp: -1,
  user_enabled: -1,
  email_referrer: -1,
  user_type: -1,
  user_active_project: "",
  activity_current_title: "",
  user_current_activity: "",
  user_status: "",
  created_d: "",
  updated_d: "",
  collabs: [],
  profile_roles: [],
  projects: [],
  addtl_info: "",
  tags: [],
  profile_photo: null,
};
