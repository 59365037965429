import React from "react";
import path from "path";
import { STLViewer, OBJViewer } from "react-stl-obj-viewer";

export default function STLView(props: { docName: string; docPath: string }) {
  return (
    <>
      {path.extname(props.docName).toLowerCase() === ".stl" && (
        <STLViewer url={props.docPath} width={628} />
      )}
      {path.extname(props.docName).toLowerCase() === ".obj" && (
        <OBJViewer url={props.docPath} />
      )}
    </>
  );
}
