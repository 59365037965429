import React from "react";
import { connect } from "react-redux";
import AppState from "../../types/AppState";
import _ from "lodash";
import Text from "./Text";

const MessageBars = (props: { appState: AppState }) => (
  <>
    {_.map(
      props.appState.flash.messages,
      (flash: {
        id: number;
        message: string;
        isError: boolean;
        props: any;
      }) => (
        <div
          style={{
            position: "fixed",
            textAlign: "center",
            top: 10,
            left: 10,
            borderRadius: 10,
            fontSize: 14,
            zIndex: 1000001,
            opacity: 0.9,
            color: "dark-gray",
            backgroundColor: flash.isError ? "#F22B29" : "#3BB273",
          }}
          className="wib-message-bar-container"
        >
          <div
            className={`wib-message wib-message-${
              flash.isError ? "error" : "success"
            }`}
          >
            <Text>{flash.message}</Text>
          </div>
        </div>
      )
    )}
  </>
);

const mapStateToProps = (appState: AppState) => ({ appState });
export default connect(mapStateToProps)(MessageBars);
