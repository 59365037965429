import base_url from "./base_url";
import request from "./request_helper";
import Profile from "../types/Profile";

export default async function execute(user_id: number): Promise<Profile> {
  return await request({
    method: "GET",
    baseURL: base_url,
    url: `users/${user_id}/render`,
  });
}
