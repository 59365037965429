import React from "react";
import Text from "../../Atoms/Text";
import AppState from "../../../types/AppState";
import { connect } from "react-redux";

const Objective = (props: { objective: string }) => (
  <div className="wib-objective">
    <Text
      containerClass="wib-objective-text-container"
      textClass="wib-objective-text"
    >
      {props.objective}
    </Text>
  </div>
);

const mapStateToProps = (state: AppState) => ({
  objective: state.project.proj_desc,
});
export default connect(mapStateToProps)(Objective);
