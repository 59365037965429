import BASE_URL from "./base_url";
import Project from "../types/Project";
import request from "./request_helper";

export interface Args {
  // focus_id: number;
  // user_id: number;
  // accept: number;
  // primary_email: string;
  // username: string;
  // password: string;
  // firstname: string;
  // lastname: string;
}

export default async function execute(args: any): Promise<Project> {
  return await request({
    method: "PUT",
    baseURL: BASE_URL,
    url: `users/accept_invite`,
    data: args,
  });
}
