import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import forgot_password from "../../requests/forgot_password";
import * as Yup from "yup";

interface Props extends IProps {
  onSuccess?: VoidFunction;
  onFail?: VoidFunction;
}

type Values = {
  email: string;
};

interface State {
  loading: boolean;
}

class ForgotPassword extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    try {
      this.setState({ loading: true });
      await forgot_password({ email: values.email });
      /* await request(...) */

      this.setState({ loading: false });
      this.props.dispatch(
        flashMessage(`Successfully sent password reset link`)
      );
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
      this.props.onFail && this.props.onFail();
    }
  };

  render() {
    const { project } = this.props.appState;

    const initialValues = { email: "" };
    const formName = "forgotpassword";
    const validationSchema = Yup.object().shape({
      email: Yup.string().required("required"),
    });
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
        }) => (
          <Form className={`wf wf-form wf-${formName}`} name={formName}>
            <FormField
              onBlur={handleBlur}
              labelText="Email"
              formName={formName}
              itemName="email"
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email && (
              <div className="wib-error">{errors.email}</div>
            )}
            <FormSubmit
              disabled={this.state.loading}
              formName={formName}
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading"}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(ForgotPassword);
