import Integration from "./Integration";
import User from "./User";
import Role from "./Role";
import Category from "./Category";
import Subcategory from "./Subcategory";
import Process from "./Process";
import { Tag } from "./Tag";
import Module from "./Module";

export default interface Project {
  project_id: number;
  title: string;
  organization: string;
  proj_desc: string;
  user_tags_init: string;
  project_status: string;
  category_id: number;
  subcategory_id: number;
  integrations: Integration[];
  users: User[];
  roles: Role[];
  categories: Category[];
  subcategories: Subcategory[];
  processes: Process[];
  tags: Tag[];
  modules: Module[];
  isAdmin: boolean;
  Startdate: string;
  total_views: number;
}

export const initial_project: Project = {
  project_id: -1,
  title: "",
  organization: "",
  proj_desc: "",
  user_tags_init: "",
  project_status: "",
  category_id: -1,
  subcategory_id: -1,
  integrations: [],
  users: [],
  roles: [],
  categories: [],
  subcategories: [],
  processes: [],
  tags: [],
  isAdmin: false,
  modules: [],
  Startdate: "",
  total_views: 0,
};
