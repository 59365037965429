import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import add_integration from "../../requests/add_integration";
import Text from "../Atoms/Text";
import { fetch_project } from "../../ducks/project";
import * as Yup from "yup";
import hasProtocol from "../../hasProtocol";

interface Props {
  appState: AppState;
  dispatch: ThunkDispatch<AppState, any, any>;
  onSuccess?: VoidFunction;
}

type Values = {
  label: string;
  description: string;
  username: string;
  password: string;
  url: string;
  hasCredentials: boolean;
};

interface State {
  loading: boolean;
}

class AddIntegration extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    try {
      this.setState({ loading: true });
      console.log(`protocol: ${hasProtocol(values.url)}`);
      await add_integration({
        ...values,
        url: `${hasProtocol(values.url) ? "" : "https://"}${values.url}`,
        username: values.hasCredentials ? values.username : "",
        password: values.hasCredentials ? values.password : "",
        project_id: this.props.appState.project.project_id,
      });
      this.setState({ loading: false });
      this.props.dispatch(
        flashMessage(`Successfully added integration: ${values.label}`)
      );
      this.props.dispatch(
        fetch_project(this.props.appState.project.project_id)
      );
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;
    const initialValues = {
      url: "",
      label: "",
      description: "",
      username: "",
      password: "",
      hasCredentials: false,
    };
    const validationSchema = Yup.object().shape({
      url: Yup.string().required("required"),
      label: Yup.string().required("required"),
      // description: Yup.string().required("required"),
    });
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form className="wf wf-form wf-addintegration" name="addintegration">
            <FormField
              onBlur={handleBlur}
              labelText="Label"
              formName="addintegration"
              itemName="label"
              value={values.label}
              onChange={handleChange}
            />
            {errors.label && touched.label && (
              <div className="wib-error">{errors.label}</div>
            )}
            <FormField
              onBlur={handleBlur}
              labelText="Description"
              formName="addintegration"
              itemName="description"
              value={values.description}
              onChange={handleChange}
            />
            {errors.description && touched.description && (
              <div className="wib-error">{errors.description}</div>
            )}
            <FormField
              onBlur={handleBlur}
              labelText="URL"
              formName="addintegration"
              itemName="url"
              value={values.url}
              onChange={handleChange}
            />
            {errors.url && touched.url && (
              <div className="wib-error">{errors.url}</div>
            )}
            <FormField
              onBlur={handleBlur}
              labelText="Include Credentials?"
              formName="addintegration"
              itemName="hasCredentials"
              value={values.hasCredentials}
              onChange={handleChange}
              type="checkbox"
            />
            {
              <div
                style={{ display: values.hasCredentials ? "block" : "none" }}
              >
                <>
                  <FormField
                    labelText="Username"
                    formName="addintegration"
                    itemName="username"
                    value={values.username}
                    onChange={handleChange}
                  />
                  <FormField
                    labelText="Password"
                    formName="addintegration"
                    itemName="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </>
              </div>
            }
            <FormSubmit
              disabled={this.state.loading}
              formName="collabs"
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading..."}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(AddIntegration);
