import React, { useState } from "react";
import TagFormat from "../../types/TagFormat";
import Tag from "./Tag";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import { Tag as ITag } from "../../types/Tag";
import User from "../../types/User";

interface Props extends IProps {
  max?: number;
  format: TagFormat;
  tags: ITag[];
  users?: { user_id: number; username: string }[];
  link?: (tag: string) => string;
  activeTags?: string[];
}

const Tags = (props: Props) => {
  const [filter, setFilter] = useState("");
  const [expanded, setExpanded] = useState(false);
  const sortedTags = _.sortBy(props.tags.map((tag) => tag.weight));
  const maxWeight = sortedTags[sortedTags.length - 1];
  const minWeight = sortedTags[0];
  return (
    <div
      className={`tag-container tag-container-tags tag-container-${
        props.format
      } tag-container-${expanded ? "expanded" : "collapsed"}`}
    >
      <input
        placeholder="Filter tags"
        className="wib-tags-filter wib-input"
        onChange={(e) => setFilter(e.target.value)}
      />
      {props.users && (
        <div className="tag-container tag-container tag-container-users">
          {props.users
            .filter((user) => user.username.includes(filter))
            .map((user) => (
              <Tag
                size={3}
                text={user.username}
                id={user.user_id}
                type="user"
              />
            ))}
        </div>
      )}
      {props.tags
        .filter((tag) =>
          tag.tag.toLocaleLowerCase().includes(filter.toLowerCase())
        )
        .map((tag) => {
          const weight = tag.weight / maxWeight;
          let size = 1;
          switch (true) {
            case weight <= 1 / 5 || weight === 0:
              size = 1;
              break;
            case weight <= 2 / 5:
              size = 2;
              break;
            case weight <= 3 / 5:
              size = 3;
              break;
            case weight <= 4 / 5:
              size = 4;
              break;
            default:
              size = 5;
              break;
          }
          return (
            <Tag
              isActive={
                props.activeTags !== undefined
                  ? props.activeTags!.includes(tag.tag)
                  : false
              }
              link={props.link ? props.link(tag.tag) : undefined}
              size={size}
              text={tag.tag}
              id={tag.id}
              type="tag"
            />
          );
        })}
      <button
        onClick={() => setExpanded(!expanded)}
        className="wb-tag-clist-viewall"
      >
        {expanded ? "show less" : "show more"}
      </button>
    </div>
  );
};

export default connector(Tags);
