import React from "react";
import AppState from "../../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import EditIcon from "../../Atoms/EditIcon";
import Text from "../../Atoms/Text";

interface Props {
  appState: AppState;
  dispatch: ThunkDispatch<AppState, any, any>;
}

interface State {}

const mapStateToProps = (appState: AppState) => ({ appState });
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, any, any>) => ({
  dispatch,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

class TitleBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { editing: false, hovering: false };
  }

  render() {
    const { project } = this.props.appState;
    return (
      <>
        <div className="wib-titlebar">
          <Text
            containerClass="wib-titlebar-text-container"
            textClass="wib-titlebar-text"
          >
            {project.title}
          </Text>
          <Text
            containerClass="wib-project-date-text-container"
            textClass="wib-project-date-text"
          >
            {project.Startdate}
          </Text>
          <Text
            containerClass="wib-project-views-text-container"
            textClass="wib-project-views-text"
          >
            {project.total_views.toLocaleString("en")}
          </Text>
          <EditIcon />
        </div>
      </>
    );
  }
}

export default connector(TitleBar);
