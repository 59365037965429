import * as Yup from "yup";
import check_username from "./requests/check_username";
import check_email from "./requests/check_email";

export const usernameValidator = Yup.string()
  .matches(/^[\w.]+$/, "username can only have numbers, letters, _s and .s")
  .test("checkDuplicateUsername", "username is taken", async (email) => {
    try {
      const has_user = await check_username({ email });
      return !has_user as boolean;
    } catch (err) {
      return false;
    }
  });

export const emailValidator = Yup.string()
  .email("must be an email")
  .test("checkDuplicateEmail", "email already exists", async (email) => {
    try {
      const has_user = await check_email({ email });
      return !has_user as boolean;
    } catch (err) {
      return false;
    }
  });

export const emailBase = Yup.string().email("must be an email");
export const usernameBase = Yup.string().matches(
  /^[\w.]+$/,
  "username can only have numbers, letters, _s and .s"
);

export default { usernameValidator, emailValidator, emailBase, usernameBase };
