import React from "react";
import connector, { IProps } from "../common_connector";
import { set_loading } from "../ducks/loading";
import { fetch_users } from "../ducks/users";
import Spinner from "react-spinners/GridLoader";
import { Link } from "react-router-dom";

interface Props extends IProps {}

class Users extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(set_loading(true));
    this.props.dispatch(fetch_users());
    this.props.dispatch(set_loading(false));
  }

  render() {
    return (
      <>
        <Spinner loading={this.props.appState.loading} />
        <div className="wib-content wib-users-list">
          <div className="users-list-users plp wib-collection">
            {this.props.appState.users.map((user) => (
              <div className="wib-user">
                <Link
                  to={`/profile/${user.id}`}
                >{`${user.firstname} ${user.lastname}`}</Link>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default connector(Users);
