import React, { Component } from "react";
import PDFView from "./PDFView";
import WibModal, { Props as ModalProps } from "./WibModal";

export interface Props extends ModalProps {
  docName: string;
  docPath: string;
}

interface State {
  currentPage: number;
  numPages: number;
}

export default class PDFModal extends Component<Props, State> {
  state = { currentPage: 1, numPages: 0 };

  render() {
    return (
      <WibModal title={this.props.docName} {...this.props}>
        <PDFView docPath={this.props.docPath} />
      </WibModal>
    );
  }
}
