import React from "react";
import Text from "../../Atoms/Text";
import EditIcon from "../../Atoms/EditIcon";
import { KEY_TEXT, VALUE_TEXT } from "../../../classnames";
import User from "../../../types/User";
import DeleteIcon from "../../Atoms/DeleteIcon";

export default (props: {
  contributor: User;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
}) => {
  return (
    <div
      className="collab"
      style={{ cursor: "pointer" }}
      onClick={props.onEdit}
    >
      <Text
        textClass={KEY_TEXT}
      >{`${props.contributor.firstname} ${props.contributor.lastname}`}</Text>
      <Text textClass={VALUE_TEXT}>
        {props.contributor.collab_type === 0 ||
        props.contributor.collab_type === 1
          ? "primary"
          : "contributor"}
      </Text>
      <div className="wib-actions-container wib-actions-container-collab">
        <EditIcon onClick={props.onEdit} />
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();
            props.onDelete();
          }}
        />
      </div>
    </div>
  );
};
