import React, { PropsWithChildren } from "react";

export interface Props {
  isOpen: boolean;
  title?: string;
  onDismiss?: VoidFunction;
  additionalClass?: string;
}

export default function WibModal(props: PropsWithChildren<Props>) {
  return (
    <div
      onMouseDown={(e: any) => {
        if (e.target.className.includes("wib-modal-container"))
          props.onDismiss && props.onDismiss();
      }}
      className={`wib-modal-container ${
        props.additionalClass && props.additionalClass
      } ${props.isOpen && "wib-modal-open"}`}
      // style={{
      //   display: props.isOpen ? "block" : "none",
      //   position: "fixed",
      //   zIndex: 1,
      //   left: 0,
      //   top: 0,
      //   width: "100%",
      //   height: "100%",
      //   overflow: "auto",
      //   backgroundColor: "rgba(0,0,0,0.4)",
      // }}
    >
      <div
        className="wib-modal"
        style={{
          backgroundColor: "white",
          margin: "15% auto",
          width: "40%",
          zIndex: 100,
        }}
      >
        <div
          className="wib-modal-title"
          style={{
            backgroundColor: "#132a34",
            color: "white",
            padding: "5px",
            textDecoration: "uppercase",
          }}
        >
          <h2 className="wib-titlebar-text">{props.title || ""}</h2>
          <div
            className="modal-close-button"
            onMouseDown={(e: any) => {
              if (e.target.className.includes("modal-close-button"))
                props.onDismiss && props.onDismiss();
            }}
          >
            <img src="" className="modal-close-icon" />
          </div>
        </div>
        <div className="wib-modal-content" style={{ fontSize: 15 }}>
          {props.isOpen && props.children}
        </div>
      </div>
    </div>
  );
}
