import axios, { AxiosRequestConfig } from "axios";
import WIBError from "../types/WIBError";
import { get as lsGet } from "local-storage";

export default async function wrap<T>(request: AxiosRequestConfig) {
  try {
    return (
      await axios({
        ...request,
        headers: {
          Authorization: `bearer ${lsGet("token")}`,
        },
      })
    ).data;
  } catch (err) {
    if (err.response && err.response.data) {
      const { data } = err.response;
      throw new WIBError(data.error || data.message, data.code, data.status);
    } else {
      throw new WIBError(
        "An unexpected error occurred",
        "40",
        err.response ? err.response.status : err
      );
    }
  }
}
