import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import { fetch_project } from "../../ducks/project";
import { initial_integration } from "../../types/Integration";
import * as Yup from "yup";
import hasProtocol from "../../hasProtocol";

interface Props {
  selectedIntegration: number;
  appState: AppState;
  dispatch: ThunkDispatch<AppState, any, any>;
  onSuccess?: VoidFunction;
}

type Values = {
  label: string;
  url: string;
  description: string;
  username: string;
  password: string;
  hasCredentials: boolean;
};

interface State {
  loading: boolean;
}

class EditIntegration extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    const { project } = this.props.appState;
    try {
      this.setState({ loading: true });
      await update_integration({
        ...values,
        url: `${hasProtocol(values.url) ? "" : "https://"}${values.url}`,
        username: values.hasCredentials ? values.username : "",
        password: values.hasCredentials ? values.password : "",
        id: this.props.selectedIntegration,
        project_id: project.project_id,
      });
      this.setState({ loading: false });
      this.props.dispatch(
        flashMessage(`Successfully edited integration: ${values.label}`)
      );
      this.props.dispatch(
        fetch_project(this.props.appState.project.project_id)
      );
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;
    const selected_integration =
      _.find(project.integrations, {
        id: this.props.selectedIntegration,
      }) || initial_integration;
    const initialValues = {
      url: selected_integration.url,
      label: selected_integration.label,
      description: selected_integration.description,
      username: selected_integration.username,
      password: selected_integration.password,
      hasCredentials:
        selected_integration.username !== "" &&
        selected_integration.password !== "",
    };
    const validationSchema = Yup.object().shape({
      url: Yup.string().required("required"),
      label: Yup.string().required("required"),
      // description: Yup.string().required("required"),
    });

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
        }) => (
          <Form
            className="wf wf-form wf-editintegration"
            name="editintegration"
          >
            <FormField
              onBlur={handleBlur}
              labelText="Label"
              formName="editintegration"
              itemName="label"
              value={values.label}
              onChange={handleChange}
            />
            {errors.label && touched.label && (
              <div className="wib-error">{errors.label}</div>
            )}
            <FormField
              onBlur={handleBlur}
              labelText="Description"
              formName="editintegration"
              itemName="description"
              value={values.description}
              onChange={handleChange}
            />
            {errors.description && touched.description && (
              <div className="wib-error">{errors.description}</div>
            )}
            <FormField
              onBlur={handleBlur}
              labelText="URL"
              formName="editintegration"
              itemName="url"
              value={values.url}
              onChange={handleChange}
            />
            {errors.url && touched.url && (
              <div className="wib-error">{errors.url}</div>
            )}
            <FormField
              labelText="Include Credentials?"
              formName={"editintegration"}
              itemName="hasCredentials"
              value={values.hasCredentials}
              onChange={handleChange}
              type="checkbox"
            />
            <div style={{ display: values.hasCredentials ? "block" : "none" }}>
              <FormField
                labelText="Username"
                formName="editintegration"
                itemName="username"
                value={values.username}
                onChange={handleChange}
              />

              <FormField
                labelText="Password"
                formName="editintegration"
                itemName="password"
                value={values.password}
                onChange={handleChange}
              />
            </div>

            <FormSubmit
              disabled={this.state.loading}
              formName="collabs"
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading"}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(EditIntegration);
