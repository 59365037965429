export default () => {
  const host = window.location.host.split(".");
  const env =
    host[0] === "localhost:3000"
      ? "origin"
      : host.length > 2
      ? host[0]
      : "production";
  return env;
};
