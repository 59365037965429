import base_url from "./base_url";
import request from "./request_helper";

export default async function execute(): Promise<any> {
  return await request({
    method: "POST",
    baseURL: base_url,
    url: `/users/passwordreset`,
  });
}
