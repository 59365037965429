import React, { Component } from "react";
import WibModal from "./Atoms/WibModal";
import querystring from "querystring";
import { Redirect } from "react-router";
import Login from "./Login";
import Search from "./Search";
import NewProject from "./Forms/NewProject";
import PasswordReset from "./Forms/PasswordReset";
import ForgotPassword from "./Forms/ForgotPassword";
import AcceptInvite from "./Forms/AcceptInvite";

interface State {
  redirectToHome: boolean;
}

export default class Home extends Component<any, State> {
  state = { redirectToHome: false };

  redirectToHome = () => this.setState({ redirectToHome: true });

  render() {
    const { search } = window.location;
    const searchParams = querystring.parse(search.replace("?", ""));

    if (this.state.redirectToHome) return <Redirect to="/" />;
    return (
      <div>
        <WibModal
          additionalClass="login-modal"
          isOpen={searchParams.login !== undefined}
          onDismiss={this.redirectToHome}
          title="Login"
        >
          <Login onSuccess={this.redirectToHome} />
        </WibModal>
        <WibModal
          additionalClass="new-project"
          isOpen={searchParams.signup !== undefined}
          onDismiss={this.redirectToHome}
          title="Signup"
        >
          <NewProject onSuccess={this.redirectToHome} />
        </WibModal>
        <WibModal
          additionalClass="forgot-password"
          isOpen={searchParams.forgotpassword !== undefined}
          onDismiss={this.redirectToHome}
          title="Forgot Password"
        >
          <ForgotPassword onSuccess={this.redirectToHome} />
        </WibModal>
        <WibModal
          isOpen={searchParams.passwordreset !== undefined}
          onDismiss={this.redirectToHome}
          title="Forgot Password"
        >
          <PasswordReset onSuccess={this.redirectToHome} />
        </WibModal>
        <WibModal
          additionalClass="accept-invitation"
          isOpen={searchParams.accept_invite !== undefined}
          onDismiss={this.redirectToHome}
          title="Accept Invitation"
        >
          <AcceptInvite onSuccess={this.redirectToHome} />
        </WibModal>
        <Search location={this.props.location} />
      </div>
    );
  }
}
