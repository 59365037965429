import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import {
  reducer as flashReducer,
  middleware as flashMiddleware,
} from "redux-flash";
import project from "./ducks/project";
import loading from "./ducks/loading";
import profile from "./ducks/profile";
import expanded from "./ducks/expanded";
import projects from "./ducks/projects";
import tags from "./ducks/tags";
import users from "./ducks/users";
import roleOptions from "./ducks/roleOptions";

const composeEnhancers =
  typeof window === "object" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const rootReducer = combineReducers({
  flash: flashReducer,
  project,
  loading,
  profile,
  expanded,
  projects,
  users,
  roleOptions,
  tags,
});
const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(flashMiddleware({ timeout: 5000 }), thunk))
);
export default store;
