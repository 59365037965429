export default interface Integration {
  label: string;
  description: string;
  username: string;
  password: string;
  url: string;
  id: number;
}

export const initial_integration = {
  label: "",
  description: "",
  username: "",
  password: "",
  id: -1,
  url: "",
};
