import React, { useState } from "react";
import Logo from "./logo.png";
import { useRouteMatch } from "react-router";
import _ from "lodash";
import { Link } from "react-router-dom";
import getEnv from "../../getEnv";
import getCurrentUserID from "../../getCurrentUserID";
import needsLogin from "../../needsLogin";
import { clear } from "local-storage";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import UserContext from "../../UserContext";

export default (props: {
  onEditToggle: VoidFunction;
  onCreate: VoidFunction;
}) => {
  const match = useRouteMatch();
  const selected = match.path.split("/")[1].toLowerCase();
  console.log(match.url);
  const env = getEnv();
  return (
    <UserContext.Consumer>
      {({ setNeedsLogin }) => (
        <div className="wib-header">
          <div className="wib-logobox">
            <Link to="/">
              <img
                src={`https://static.whatimbuilding.com/${env}/images/wib-logo.png`}
                className="wib-logo"
                alt="logo"
              />
            </Link>
          </div>
          <div className="wib-header-icons">
            <div className="wib-header-icon-container">
              <div onClick={() => props.onCreate()}>
                <img
                  src={`https://static.whatimbuilding.com/${env}/images/wib-header-plus.png`}
                  alt="Add"
                />
              </div>
            </div>
            <div className="wib-header-icon-container">
              <Link
                to={
                  !needsLogin() ? `/profile/${getCurrentUserID()}` : "/?login=1"
                }
              >
                <img
                  src={`https://static.whatimbuilding.com/${env}/images/wib-header-account.png`}
                  alt="users"
                />
              </Link>
            </div>
            {!needsLogin() && (
              <div
                onClick={() => {
                  clear();
                  setNeedsLogin(true);
                }}
                className="wib-header-icon-container"
              >
                <Link to="/?login=1">
                  <img
                    src="https://staging.whatimbuilding.com/images/wib-header-settings.png"
                    alt="Settings"
                  />
                </Link>
              </div>
            )}
          </div>
          <div className="wib-nav">
            {_.map(["our mission", "projects", "faq", "contact us"], (name) => (
              <Link
                key={name}
                to={`/${name.replace(" ", "_")}`}
                className={`wib-nav-item ${
                  name === selected ? "wib-nav-item-selected" : ""
                }`}
              >
                {name.toUpperCase()}
              </Link>
            ))}
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
};
