import React from "react";
import PDFView from "./PDFView";
import STLView from "./STLView";
import TextView from "./TextView";
import path from "path";
import isImage from "is-image";

export default function WIBMediaViewer(props: {
  docName: string;
  docPath: string;
}) {
  return (
    <div>
      {path.extname(props.docPath).toLowerCase() === ".txt" && (
        <TextView file={props.docPath} />
      )}
      {path.extname(props.docPath).toLowerCase() === ".pdf" && (
        <PDFView {...props} />
      )}
      {(path.extname(props.docPath).toLowerCase() === ".stl" ||
        path.extname(props.docName).toLowerCase() === ".obj") && (
        <STLView {...props} />
      )}
      {isImage(props.docName) &&
        path.extname(props.docName).toLowerCase() !== ".stl" && (
          <img src={props.docPath} alt={props.docName} />
        )}
    </div>
  );
}
