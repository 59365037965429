import React, { MouseEventHandler } from "react";
import getEnv from "../../../getEnv";

export default (props: { hovering?: boolean; onClick?: MouseEventHandler }) => (
  <div className="wib-delete-container" onClick={props.onClick}>
    <img
      src={`https://static.whatimbuilding.com/${getEnv()}/images/wib-delete-off.png`}
      alt="delete"
    />
  </div>
);
