import React from "react";
import Text from "../../Atoms/Text";
import AppState from "../../../types/AppState";
import _ from "lodash";
import { connect } from "react-redux";
import { KEY_TEXT, VALUE_TEXT } from "../../../classnames";

const Category = (props: {
  category: string;
  focus: string;
  organization: string;
}) => {
  return (
    <div className="wib-category">
      <Text textClass={KEY_TEXT}>category</Text>
      <Text textClass={VALUE_TEXT}>{props.category}</Text>
      <Text textClass={KEY_TEXT}>subcategory</Text>
      <Text textClass={VALUE_TEXT}>{props.focus}</Text>
      {props.organization !== "" && (
        <>
          <Text textClass={KEY_TEXT}>organization</Text>
          <Text textClass={VALUE_TEXT}>{props.organization}</Text>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const found_category = _.find(state.project.categories, {
    id: state.project.category_id,
  });
  const found_focus = _.find(state.project.subcategories, {
    id: state.project.subcategory_id,
  });

  return {
    organization: state.project.organization,
    category: found_category !== undefined ? found_category!.category : "-",
    focus: found_focus !== undefined ? found_focus!.subcategory : "-",
  };
};

export default connect(mapStateToProps)(Category);
