import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select, { OptionTypeBase } from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import Spinner from "react-spinners/GridLoader";
import needsLogin from "../../needsLogin";
import Category from "../../types/Category";
import Subcategory from "../../types/Subcategory";
import fetch_categories from "../../requests/fetch_categories";
import fetch_subcategories from "../../requests/fetch_subcategories";
import create_project_authed from "../../requests/create_project_authed";
import create_project from "../../requests/create_project";
import { Redirect } from "react-router-dom";
import { set as lsSet } from "local-storage";
import * as Yup from "yup";
import UserContext from "../../UserContext";
import check_email from "../../requests/check_email";
import check_username from "../../requests/check_username";

interface Props extends IProps {
  onSuccess?: VoidFunction;
}

type Values = {
  title: string;
  category: OptionTypeBase;
  subcategory: OptionTypeBase;
  purpose: string;
  description: string;
  email: string;
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  categories: OptionTypeBase[];
  subcategories: OptionTypeBase[];
};

interface State {
  loading: boolean;
  initialValues: Values;
  projectID: number;
  success: boolean;
}

class NewProject extends React.Component<Props, State> {
  state = {
    loading: true,
    success: false,
    projectID: -1,
    initialValues: {
      title: "",
      category: { label: "", value: -1 },
      subcategory: { label: "", value: -1 },
      purpose: "",
      description: "",
      email: "",
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      categories: [],
      subcategories: [],
    },
  };

  handleSubmit = async (
    values: Values,
    setNeedsLogin: (arg0: boolean) => void
  ) => {
    try {
      const args = {
        project_title: values.title,
        purpose: values.purpose,
        proj_desc: values.description,
        category: values.category.value,
        subcategory: values.subcategory.value,
      };
      this.setState({ loading: true });
      if (!needsLogin()) {
        const result = await create_project_authed(args);
        if (result.project_id) {
          this.setState({
            loading: false,
            projectID: result.project_id,
            success: true,
          });
        }
      } else {
        const result = await create_project({
          ...args,
          primary_email: values.email,
          firstname: values.firstname,
          lastname: values.lastname,
          username: values.username,
          password: values.password,
        });
        if (result.token) {
          lsSet("token", result.token);
        }
        if (result.project_id) {
          this.setState({
            loading: false,
            success: true,
            projectID: result.project_id,
          });
        }
      }
      setNeedsLogin(false);
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  async componentDidMount() {
    try {
      const categories = await fetch_categories();
      const subcategories = await fetch_subcategories();
      console.log(JSON.stringify(categories));
      this.setState({
        loading: false,
        initialValues: {
          ...this.state.initialValues,
          categories,
          subcategories,
        },
      });
    } catch (err) {
      this.props.dispatch(flashErrorMessage(err.message));
    }
  }

  render() {
    const { project } = this.props.appState;
    console.log(this.state.initialValues.categories);
    const { initialValues } = this.state;
    const formName = "newproject";
    const categoryOptions = initialValues.categories.map((cat: Category) => ({
      label: cat.category,
      value: cat.id,
    }));
    const optionTypeSchema = Yup.object().shape({
      label: Yup.string().required("required"),
      value: Yup.number().min(0).required("required"),
    });
    const newUserRequired = (value: any) => {
      const test = !needsLogin() || (value !== "" && value !== undefined);
      console.log(test, value);
      return test;
    };
    const validationSchema = Yup.object().shape({
      title: Yup.string().required("required"),
      // purpose: Yup.string().required("required"),
      description: Yup.string().required("required"),
      category: optionTypeSchema.required("required"),
      subcategory: optionTypeSchema.required("required"),
      email: Yup.string()
        .email("must be an email")
        .test("required-for-newuser", "required", newUserRequired)
        .test("checkDuplicateEmail", "email already exists", async (email) => {
          try {
            const has_user = await check_email({ email });
            return !has_user as boolean;
          } catch (err) {
            return false;
          }
        }), //.required("required"),
      firstname: Yup.string().test(
        "required-for-newuser",
        "required",
        newUserRequired
      ),
      lastname: Yup.string().test(
        "required-for-newuser",
        "required",
        newUserRequired
      ),
      username: Yup.string()
        .matches(
          /^[\w.]+$/,
          "username can only have numbers, letters, _s and .s"
        )
        .test("required-for-newuser", "required", newUserRequired)
        .test("checkDuplicateUsername", "username is taken", async (email) => {
          try {
            const has_user = await check_username({ email });
            return !has_user as boolean;
          } catch (err) {
            return false;
          }
        }),
      password: Yup.string()
        .min(6, "password must be at least 6 characters long")
        .test("required-for-newuser", "required", newUserRequired),
    });

    return (
      <UserContext.Consumer>
        {({ setNeedsLogin }) => (
          <>
            {this.state.success && (
              <Redirect to={`/projects/${this.state.projectID}`} />
            )}

            {this.state.loading ? (
              <Spinner loading={this.state.loading} />
            ) : (
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values) => this.handleSubmit(values, setNeedsLogin)}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  touched,
                  errors,
                }) => (
                  <Form className={`wf wf-form wf-${formName}`} name={formName}>
                    <FormField
                      onBlur={handleBlur}
                      labelText="Project Title"
                      formName={formName}
                      itemName="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    {errors.title && touched.title && (
                      <div className="wib-error">{errors.title}</div>
                    )}
                    <FormField
                      labelText="Category"
                      formName="newproject"
                      itemName="category"
                      value={values.category}
                      onChange={handleChange}
                      customInput={() => (
                        <Select
                          name="category"
                          onBlur={handleBlur}
                          value={values.category}
                          options={categoryOptions}
                          onChange={(val) => setFieldValue("category", val)}
                        />
                      )}
                    />
                    {errors.category && (
                      <div className="wib-error">required</div>
                    )}
                    <FormField
                      labelText="Subcategory"
                      formName="newproject"
                      itemName="subcategory"
                      value={values.subcategory}
                      onChange={handleChange}
                      customInput={() => (
                        <Select
                          name="subcategory"
                          onBlur={handleBlur}
                          value={values.subcategory}
                          onChange={(val) => setFieldValue("subcategory", val)}
                          options={initialValues.subcategories
                            .filter((subcat: Subcategory) => {
                              console.log(
                                subcat.parent_id,
                                JSON.stringify(subcat)
                              );
                              return subcat.parent_id === values.category.value;
                            })
                            .map((subcat: Subcategory) => ({
                              label: subcat.subcategory,
                              value: subcat.id,
                            }))}
                        />
                      )}
                    />
                    {errors.subcategory && (
                      <div className="wib-error">required</div>
                    )}
                    <FormField
                      onBlur={handleBlur}
                      labelText="Organization (optional)"
                      formName="newproject"
                      itemName="purpose"
                      value={values.purpose}
                      onChange={handleChange}
                    />
                    {/* {errors.purpose && touched.purpose && (
                      <div className="wib-error">{errors.purpose}</div>
                    )} */}
                    <FormField
                      onBlur={handleBlur}
                      labelText="Description"
                      formName={formName}
                      itemName="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                    {errors.description && touched.description && (
                      <div className="wib-error">{errors.description}</div>
                    )}
                    <div style={{ display: needsLogin() ? "block" : "none" }}>
                      <FormField
                        onBlur={handleBlur}
                        labelText="Email"
                        formName="newproject"
                        itemName="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <div className="wib-error">{errors.email}</div>
                      )}
                      <FormField
                        onBlur={handleBlur}
                        labelText="Username"
                        formName="newproject"
                        itemName="username"
                        value={values.username}
                        onChange={handleChange}
                      />
                      {errors.username && touched.username && (
                        <div className="wib-error">{errors.username}</div>
                      )}
                      <FormField
                        onBlur={handleBlur}
                        labelText="Password"
                        formName={formName}
                        itemName="password"
                        value={values.password}
                        onChange={handleChange}
                        type="password"
                      />
                      {errors.password && touched.password && (
                        <div className="wib-error">{errors.password}</div>
                      )}
                      <FormField
                        onBlur={handleBlur}
                        labelText="First Name"
                        formName={formName}
                        itemName="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                      />
                      {errors.firstname && touched.firstname && (
                        <div className="wib-error">{errors.firstname}</div>
                      )}
                      <FormField
                        onBlur={handleBlur}
                        labelText="Last Name"
                        formName={formName}
                        itemName="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                      />
                      {errors.lastname && touched.lastname && (
                        <div className="wib-error">{errors.lastname}</div>
                      )}
                    </div>

                    <FormSubmit
                      formName="newproject"
                      labelText="Submit"
                      itemName="submit"
                      value="submit"
                    />
                  </Form>
                )}
              </Formik>
            )}
          </>
        )}
      </UserContext.Consumer>
    );
  }
}

export default connector(NewProject);
