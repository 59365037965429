import BASE_URL from "./base_url";
import Project from "../types/Project";
import SignupValues from "../types/SignupValues";
import request from "./request_helper";

export interface Args {
  project_id: number;
}

export default async function execute(args: SignupValues): Promise<any> {
  return request({
    method: "post",
    baseURL: BASE_URL,
    url: "users/new",
    data: args,
  });
}
