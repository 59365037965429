import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import CreatableSelect from "react-select/creatable";
import Select, { OptionTypeBase } from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import Role from "../../types/Role";
import { fetch_project } from "../../ducks/project";
import create_role_focus from "../../requests/create_role";
import getCurrentUserID from "../../getCurrentUserID";
import * as Yup from "yup";

interface Props extends IProps {
  onSuccess?: VoidFunction;
  role: Role;
}

type Values = {
  user?: OptionTypeBase;
  focus?: OptionTypeBase;
};

interface State {
  loading: boolean;
}

class AssignFocus extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    const { project } = this.props.appState;
    try {
      this.setState({ loading: true });

      const result = await create_role_focus({
        project_id: project.project_id,
        role: this.props.role.role,
        user_id: getCurrentUserID(),
        focus: values.focus!.label,
        owner_id: values.user!.value,
        user_accepted: 1,
        contrib_weight: 1,
      });

      this.setState({ loading: false });
      this.props.dispatch(
        flashMessage(`Successfully assigned user to responsibility`)
      );
      if (this.props.onSuccess) this.props.onSuccess();
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;

    const initialValues = {
      user: undefined, //{ label: "", value: -1 },
      focus: undefined, //{ label: "", value: -1 },
    };
    const formName = "assignment";

    const optionTypeSchema = Yup.object().shape({
      label: Yup.string().required("required"),
      value: Yup.number().required("required"),
    });

    const validationSchema = Yup.object().shape({
      user: optionTypeSchema.required(""),
      focus: optionTypeSchema.required(""),
    });

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          touched,
          handleBlur,
          errors,
        }) => (
          <Form className={`wf wf-form wf-${formName}`} name={formName}>
            {console.log(JSON.stringify(errors))}
            <h2>Add / Assign Work</h2>
            <FormField
              disabled
              labelText="Role"
              formName={formName}
              itemName="role"
              value={this.props.role.role}
              onChange={handleChange}
            />
            <FormField
              labelText="User"
              formName={formName}
              itemName="user"
              value={values.user}
              customInput={() => (
                <Select
                  onBlur={handleBlur}
                  options={project.users.map((user) => {
                    return {
                      label: `${user.firstname} ${user.lastname}`,
                      value: user.user_id,
                    };
                  })}
                  onChange={(v) => setFieldValue("user", v)}
                  value={values.user}
                />
              )}
            />
            {errors.user && touched.user && (
              <div className="wib-error">{"please select a user"}</div>
            )}
            <FormField
              labelText="Assignment"
              formName={formName}
              itemName="focus"
              value={values.focus}
              customInput={() => (
                <CreatableSelect
                  onBlur={handleBlur}
                  options={this.props.role.foci.map((focus) => ({
                    label: focus.focus,
                    value: focus.focus_id,
                  }))}
                  onChange={(v) => setFieldValue("focus", v)}
                  value={values.focus}
                />
              )}
            />
            {errors.focus && touched.focus && (
              <div className="wib-error">{"please select an assignment"}</div>
            )}
            <FormSubmit
              disabled={this.state.loading}
              formName={formName}
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading"}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(AssignFocus);
