import base_url from "./base_url";
import request from "./request_helper";

export interface Args {
  notification_id: number;
  user_temp: number;
  firstname: string;
  primary_email: string;
  lastname: string;
  username: string;
  password: string;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "PUT",
    baseURL: base_url,
    url: `/projects/user_accept`,
    data: args,
  });
}
