import React, { useState } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import Project from "./components/Project";
import Nav from "./components/Nav";
import _ from "lodash";
import MessageBars from "./components/Atoms/MessageBars";
import Footer from "./components/Atoms/Footer";
import Signup from "./components/Signup";
import "./app.css";
import AcceptInvite from "./components/Forms/AcceptInvite";
import Profile from "./components/Profile";
import Users from "./components/Users";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import NewProject from "./components/Forms/NewProject";
import UserContext from "./UserContext";
import checkNeedsLogin from "./needsLogin";
import Search from "./components/Search";
import { pdfjs } from "react-pdf";
import WibModal from "./components/Atoms/WibModal";
import Home from "./components/Home";
import needsLogin from "./needsLogin";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface State {
  editor: boolean;
  isCreate: boolean;
  needsLogin: boolean;
}

export default class App extends React.Component<any, State> {
  state = {
    editor: true,
    isCreate: false,
    needsLogin: false,
  };

  componentDidMount() {
    this.sNeedsLogin(needsLogin());
  }

  sNeedsLogin(needsLogin: boolean) {
    this.setState({ needsLogin });
  }

  setIsCreate = (isCreate: boolean) => {
    this.setState({ isCreate });
  };

  setEditor = (editor: boolean) => {
    this.setState({ editor });
  };

  render() {
    const { editor, isCreate, needsLogin } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter basename="/app">
          <UserContext.Provider
            value={{
              needsLogin: needsLogin,
              verifyLoggedin: () => this.sNeedsLogin(checkNeedsLogin()),
              setNeedsLogin: (loggedIn: boolean) => this.sNeedsLogin(loggedIn),
            }}
          >
            {/* {needsLogin && <Redirect to="/?login" />} */}
            {console.log("render", needsLogin)}
            <div
              className={`display-container display-container-${
                editor ? "editor" : "visitor"
              } display-container-${!needsLogin ? "authed" : "unauthed"}`}
            >
              <Nav
                onCreate={() => this.setIsCreate(true)}
                onEditToggle={() => this.setEditor(!editor)}
              />
              <WibModal
                title="New Project"
                isOpen={isCreate}
                onDismiss={() => this.setIsCreate(false)}
              >
                <NewProject onSuccess={() => this.setIsCreate(false)} />
              </WibModal>
              <MessageBars />
              <Switch>
                <Route
                  path="/projects/:project_id"
                  render={({ match, location }) => (
                    <Project
                      key={location.key}
                      match={match}
                      setEditor={this.setEditor}
                    />
                  )}
                />
                <Route
                  path="/projects"
                  render={() =>
                    _.map([1, 2, 3], (num) => (
                      <Link to={`/projects/${num}`}>Project {num}</Link>
                    ))
                  }
                />
                <Route
                  path="/accept_invite"
                  render={({ location }) => <AcceptInvite key={location.key} />}
                />
                <Route
                  path="/profile/:user_id"
                  render={({ match }) => <Profile match={match} />}
                />
                <Route
                  path="/"
                  render={({ location }) => (
                    <Home key={location.key} location={location} />
                  )}
                />
                <Route path="/users" component={Users} />
              </Switch>
              <Footer />
            </div>
          </UserContext.Provider>
        </BrowserRouter>
      </Provider>
    );
  }
}
