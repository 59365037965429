import React, { ReactElement, useState } from "react";
import Profile from "../../types/Profile";
import Text from "../Atoms/Text";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { USER_CONTENT_URL } from "../../requests/base_url";

interface Props {
  user: Profile;
  onChangeImage: (file: File) => void;
  editingImage: boolean;
  onImageEditToggle: VoidFunction;
  canEdit: boolean;
}

export default function UserDetails({
  user,
  onChangeImage,
  editingImage,
  onImageEditToggle,
  canEdit,
}: Props): ReactElement {
  return (
    <div className="profile-user-details">
      <div className="user-details-image-container">
        {editingImage ? (
          <Dropzone
            onDrop={(acceptedFiles, fileRejections, event) => {
              if (acceptedFiles.length > 0) {
                onChangeImage(acceptedFiles[0]);
              }
            }}
            accept="image/*"
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className="wib-dropzone-area">
                <input {...getInputProps()} />
                <Text name="dropzone-message">
                  <span className="wib-dropzone-filetype">
                    {isDragActive
                      ? "Drop Your New Image"
                      : "Drag and Drop Your New Image"}
                  </span>
                </Text>
              </div>
            )}
          </Dropzone>
        ) : user.profile_photo !== null ? (
          <img
            src={`${USER_CONTENT_URL}/${user.id}/${user.profile_photo}`}
            className="user-details-image-exists user-details-image"
          />
        ) : (
          <img className="user-details-image-notfound user-details-image"></img>
        )}
        {canEdit && (
          <button onClick={onImageEditToggle} className="change-profile-photo">
            {editingImage ? "cancel" : "change profile photo"}
          </button>
        )}
      </div>
      <div className="profile-user-details-position">
        {user.activity_current_title}
      </div>
      <div className="profile-user-details-description">
        {user.user_current_activity}
      </div>
      <div className="profile-user-details-lookingfor">{user.user_status}</div>
      <div className="profile-user-details-additional">{user.addtl_info}</div>
      {/* <div className="profile-user-details-colleagues">
        {user.collabs.map((collab) => (
          <div
            key={`${collab.username}-${collab.collaborator_id}`}
            className="profile-user-details-colleague"
          >
            <Text name="user-details-colleague-text">
              <Link to={`/profile/${collab.collaborator_id}`}>
                {`${collab.firstname} ${collab.lastname}`}
              </Link>
            </Text>
          </div>
        ))}
      </div> */}
    </div>
  );
}
