import Focus from "./Focus";

export default interface Role {
  role_id: number;
  role: string;
  not_pending: number;
  foci: Focus[];
}

export const initial_role = {
  role_id: -1,
  role: "",
  foci: [],
  not_pending: 1,
};
