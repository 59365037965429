import React, { MouseEventHandler } from "react";
import Add from "./add.svg";

export default (props: { text: string; onClick?: MouseEventHandler }) => (
  <div
    className="wib-add-container"
    style={{ cursor: "pointer" }}
    onClick={props.onClick}
  >
    <img src={Add} alt="add" />
    <div className="wib-add-text">{props.text}</div>
  </div>
);
