import request from "./request_helper";
import base_url from "./base_url";

export interface Args {
  project_id: number;
  process_id: number;
  step_id: number;
  filename: string;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "DELETE",
    baseURL: base_url,
    url: `projects/${args.project_id}/${args.process_id}/${args.step_id}/step_files/${args.filename}`,
  });
}
