import React, { useState, useEffect } from "react";
import Select from "react-select/creatable";
import { OptionTypeBase } from "react-select";
import { Tag } from "../../../types/Tag";
import get_tags from "../../../requests/get_tags";

interface Props {
  onBlur?: (tags: string[]) => void;
  defaultTags?: Tag[];
  maxTags: number;
  onChange?: (tags: string[]) => void;
  value?: string[];
  nativeOnBlur?: (e: any) => void;
  name?: string;
}

export default function Tags({ onBlur, defaultTags, maxTags, onChange, value, nativeOnBlur, name }: Props) {
  const [tagOptions, setTagOptions] = useState<Tag[]>(new Array<Tag>());
  const [selectedTags, setSelectedTags] = useState<OptionTypeBase[]>(
    defaultTags?.map((tag) => ({ label: tag.tag, value: tag.id })) || new Array<OptionTypeBase>()
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      try {
        const response = await get_tags(0);
        setTagOptions(response);
        setLoading(false);
      } catch (err) {
        console.log("failed to load tags");
      }
    };
    fetchTags();
  }, []);
  const tagsLeft = maxTags - Math.max(selectedTags.length, value?.length || 0);
  return (
    <div onClick={(e) => e.stopPropagation()} className="tags-wrapper">
      <Select
        name={name}
        defaultValue={selectedTags}
        value={value?.map((tag, i) => ({ label: tag, value: i * 50000 })) || selectedTags}
        isMulti
        isDisabled={loading}
        creatable
        onBlur={(e) => {
          if (nativeOnBlur) {
            nativeOnBlur(e);
            return;
          }
          onBlur && onBlur(selectedTags.map((tag) => tag.label));
        }}
        options={tagOptions.map((tag) => ({
          label: tag.tag,
          value: tag.id,
        }))}
        onChange={(v: any, x: any) => {
          console.log(v, x);
          let tags = v;
          if (v === null || tags === undefined) {
            tags = [];
          }
          if (tags.length <= maxTags) {
            const st = tags
              .map((tag: { label: string; value: any }) => ({
                label: tag.label.replace(/[!@#$%^&*(),.?":{}|<>]/g, ""),
                value: tag.value,
              }))
              .filter((tag: any) => tag.label !== "");
            onChange && onChange(st.map((tag: OptionTypeBase) => tag.label));
            setSelectedTags(st);
          }
        }}
      />

      <div className="tag-max-message">{`${tagsLeft} tag${tagsLeft !== 1 ? "s" : ""} left`}</div>
    </div>
  );
}
