import React from "react";
import { Link } from "react-router-dom";

export interface Props {
  size: number;
  text: string;
  id: number;
  type: string;
  link?: string;
  isActive?: boolean;
}

export default function Tag(props: Props) {
  return !props.isActive ? (
    <Link
      to={
        props.link !== undefined
          ? props.link
          : props.type === "tag"
          ? `/search?tag=${props.text}`
          : `/profile/${props.id}`
      }
      className={`wib-tag wib-tag-${props.type} wib-tag-project wib-tag-weight-${props.size}`}
    >
      {props.text}
    </Link>
  ) : (
    <div
      className={`wib-tag wib-tag-${props.type} wib-tag-project wib-tag-weight-${props.size} active`}
    >
      {props.text}
    </div>
  );
}
