import React, {
  ChangeEvent,
  Component,
  FunctionComponent,
  ReactElement,
} from "react";

type CustomInputFunctionParams = {
  className: string;
  value: any;
};
type CustomInputFunction = (arg0: CustomInputFunctionParams) => ReactElement;

export default (props: {
  formName: string;
  itemName: string;
  value: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  labelText?: string;
  extraInfo?: string;
  type?: string;
  customInput?: CustomInputFunction;
  onBlur?: any;
  disabled?: boolean;
}) => (
  <div className="wff-row wff-row-title">
    <div
      className={`wff-extrainfo-wrap wff-extrainfo-wrap-${props.formName}-${props.itemName}`}
    >
      <h5
        className={`wff-extrainfo wff-extrainfo-${props.formName}-${props.itemName}`}
      >
        {props.extraInfo}
      </h5>
    </div>
    <div
      className={`wff-label-wrap wff-label-wrap-${props.formName}-${props.itemName}`}
    >
      <h3 className={`wff-label wff-label-${props.formName}-${props.itemName}`}>
        {props.labelText}
      </h3>
    </div>
    <div
      className={`wff-wrap wff-wrap-text wff-wrap-${props.formName}-${props.itemName}`}
    >
      {props.customInput !== undefined ? (
        props.customInput({
          className: `wff wff-text wff-${props.formName}-${props.itemName}`,
          value: props.value,
        })
      ) : (
        <input
          disabled={props.disabled}
          onBlur={props.onBlur}
          type={props.type || "text"}
          value={props.value}
          name={props.itemName}
          checked={props.value}
          onChange={props.onChange}
          className={`wff wff-text wff-${props.formName}-${props.itemName}`}
        />
      )}
    </div>
  </div>
);
