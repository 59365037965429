import React, { useRef } from "react";

interface Props {
  editable?: boolean;
  oneLine?: boolean;
  className?: string;
  onBlur?: (content: string) => void;
  defaultValue?: string;
  value?: string;
  onChange?: (content: string) => void;
}

export default function WIBEditable(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      onKeyPress={(e) => {
        if (
          props.oneLine !== undefined &&
          props.oneLine !== null &&
          props.oneLine! &&
          e.which === 13
        ) {
          e.preventDefault();
          ref.current?.blur();
        }
      }}
      onInput={(e) =>
        props.onChange && props.onChange(e.currentTarget.innerText)
      }
      onBlur={(e) => props.onBlur && props.onBlur(e.target.innerText || "")}
      contentEditable={props.editable || false}
      className={`wib-textbox ${props.editable ? "wib-textbox-editable" : ""} ${
        props.className || ""
      } ${props.oneLine ? "wib-editable-oneline" : ""}`}
    >
      {props.value || props.defaultValue || ""}
    </div>
  );
}
