import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select, { OptionTypeBase } from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import create_role from "../../requests/create_role";
import getCurrentUserID from "../../getCurrentUserID";
import { fetch_project } from "../../ducks/project";
import edit_role_name from "../../requests/edit_role_name";
import * as Yup from "yup";

interface Props extends IProps {
  onSuccess?: VoidFunction;
  creating: boolean;
  roleID: number;
}

type Values = {
  roleName: string;
  //owner: OptionTypeBase;
};

interface State {
  loading: boolean;
}

class RoleForm extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    const { project } = this.props.appState;
    try {
      this.setState({ loading: true });

      const args = {
        project_id: project.project_id,
        role: values.roleName,
        user_id: getCurrentUserID(),
        focus: "",
        owner_id: getCurrentUserID(), //values.owner.value,
        user_accepted: 1,
        contrib_weight: 1,
      };
      if (this.props.creating) {
        await create_role(args);
      } else {
        const response = await edit_role_name({
          role_id: this.props.roleID,
          role_name: values.roleName,
          project_id: project.project_id,
        });
      }

      this.setState({ loading: false });
      this.props.dispatch(flashMessage(`Successfully Created Role`));
      this.props.dispatch(fetch_project(project.project_id));
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;
    const roleName = this.props.creating
      ? ""
      : _.find(project.roles, { role_id: this.props.roleID })!.role;

    const userOptions = project.users.map((user) => ({
      label: `${user.firstname} ${user.lastname}`,
      value: user.user_id,
    }));

    const initialValues = {
      roleName: roleName,
      // owner: _.find(userOptions, { value: getCurrentUserID() })!,
    };
    const formName = "role";

    const validationSchema = Yup.object().shape({
      roleName: Yup.string().required("required"),
    });

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
        }) => (
          <Form className={`wf wf-form wf-${formName}`} name={formName}>
            <FormField
              onBlur={handleBlur}
              labelText="Role Name"
              formName={formName}
              itemName="roleName"
              value={values.roleName}
              onChange={handleChange}
            />

            {/* <FormField
              labelText="Owner"
              formName={formName}
              itemName="owner"
              value={values.owner}
              customInput={() => (
                <Select
                  options={userOptions}
                  value={values.owner}
                  onChange={(v) => setFieldValue("owner", v)}
                />
              )}
            /> */}
            <FormSubmit
              disabled={this.state.loading}
              formName={formName}
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading"}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(RoleForm);
