import { AnyAction, Dispatch } from "redux";
import { set_loading } from "./loading";
import get_tags from "../requests/get_tags";
import { Tag } from "../types/Tag";

export const FETCH_TAGS = "wib/projects/FETCH_TAGS";
export const RECEIVE_TAGS = "wib/projects/RECEIVE_TAGS";

export type FetchTagsAction = { type: string };
export type ReceiveTagsAction = { type: string; tags: Tag[] };

export default function reducer(state: Tag[] = [], action: AnyAction) {
  switch (action.type) {
    case RECEIVE_TAGS:
      const receive_tags_action = action as ReceiveTagsAction;
      return receive_tags_action.tags;
    default:
      return state;
  }
}

export function fetch_tags() {
  return async function (dispatch: Dispatch) {
    dispatch(set_loading(true));
    const tags = await get_tags(0);
    dispatch(set_loading(false));
    dispatch(receive_tags(tags));
  };
}

export function receive_tags(tags: Tag[]) {
  return { type: RECEIVE_TAGS, tags };
}
