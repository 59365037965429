import React, { useState, MouseEventHandler } from "react";
import Text from "../../Atoms/Text";
import IIntegration from "../../../types/Integration";
import EditIcon from "../../Atoms/EditIcon";
import DeleteIcon from "../../Atoms/DeleteIcon";

interface Props {
  integration: IIntegration;
  onClick?: MouseEventHandler;
  onDelete: (id: number) => void;
}

const googleIDFromURL = (url: string) => url.match(/[-\w]{25,}/);

export default (props: Props) => {
  const { integration } = props;
  // const isGoogle = integration.url.includes("drive.google.com");
  return (
    <div onClick={props.onClick} style={{ cursor: "pointer" }} className="wib-integration">
      <div className="wib-integration-label">
        <Text name="integration-label">
          <a target="_blank" href={integration.url}>
            {integration.label}
          </a>
        </Text>
      </div>
      <div className="wib-integration-info-container">
        <div className="wib-integration-info-item">
          <Text name="info-item-key">Description:</Text>
          <Text name="info-item-value">{integration.description}</Text>
        </div>
        {integration.username !== "" && integration.password !== "" && (
          <div className="wib-integration-info-item">
            <Text name="info-item-key">Credentials:</Text>
            <Text name="credential-key">user:</Text>
            <Text name="credential-val">{integration.username || ""}</Text>
            <Text name="credential-key">pass:</Text>
            <Text name="credential-val">{integration.password || ""}</Text>
          </div>
        )}
      </div>
      {/* {isGoogle && (
        <div className="drive-view-container">
          <iframe
            className="drive-view"
            src={`https://drive.google.com/embeddedfolderview?id=${googleIDFromURL(props.integration.url)}#grid`}
          />
        </div>
      )} */}
      <div className="wib-actions-container wib-actions-container-integration">
        <EditIcon />
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();
            props.onDelete(integration.id);
          }}
        />
      </div>
    </div>
  );
};
