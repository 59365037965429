import Project, { initial_project } from "../types/Project";
import { AnyAction, Dispatch } from "redux";
import get_project from "../requests/get_project";
import WIBError from "../types/WIBError";
import { flashErrorMessage } from "redux-flash";
import { set_loading } from "./loading";

export const RECEIVE_PROJECT = "wib/project/RECEIVE_PROJECT";

export type ReceiveProjectAction = { type: string; new_project: Project };

export default function reducer(
  state: Project = initial_project,
  action: AnyAction
) {
  switch (action.type) {
    case RECEIVE_PROJECT:
      const receive_project_action = action as ReceiveProjectAction;
      return receive_project_action.new_project;
    default:
      return state;
  }
}

export function receive_project(new_project: Project): ReceiveProjectAction {
  return { type: RECEIVE_PROJECT, new_project };
}

export function fetch_project(project_id: number) {
  return async function (dispatch: Dispatch) {
    dispatch(set_loading(true));
    try {
      const result = await get_project({ project_id });
      dispatch(receive_project(result));
      dispatch(set_loading(false));
    } catch (err) {
      dispatch(set_loading(false));
      const wiberror = err as WIBError;
      flashErrorMessage(wiberror.message);
    }
  };
}
