import BASE_URL from "./base_url";
import request from "./request_helper";

export interface Args {
  project_id: number;
  owner_id: number;
  role_id: number;
  title: string;
  objective: string;
  tags: string;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "POST",
    baseURL: BASE_URL,
    url: `projects/process`,
    data: args,
  });
}
