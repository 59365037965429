import base_url from "./base_url";
import request from "./request_helper";
import qs from "qs";
import { Tag } from "../types/Tag";
import IProjectStub from "../types/ProjectStub";

export interface Args {
  tag_list: string[];
}

export interface Response {
  tags: Tag[];
  projects: IProjectStub[];
}

export default async function execute(args: Args): Promise<any> {
  console.log(JSON.stringify(args));
  return await request({
    method: "GET",
    baseURL: base_url,
    url: `/projects?${qs.stringify(args)}`,
  });
}
