import React from "react";
import { Formik, Form } from "formik";
import FormField from "../Atoms/FormField";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import Select from "react-select";
import _ from "lodash";
import update_project from "../../requests/update_project";
import { set_loading } from "../../ducks/loading";
import { flashMessage, flashErrorMessage } from "redux-flash";
import WIBError from "../../types/WIBError";
import { fetch_project } from "../../ducks/project";
import Subcategory from "../../types/Subcategory";
import * as Yup from "yup";

interface Props extends IProps {
  onSuccess?: VoidFunction;
}

type SelectOption = { label: string; value: number };

interface State {
  loading: boolean;
}

interface Values {
  project_title: string;
  category?: SelectOption;
  subcategory?: SelectOption;
  purpose: string;
  proj_desc: string;
}

class EditProjectStub extends React.Component<Props, State> {
  state = { loading: false };

  handleSubmit = async (values: Values) => {
    try {
      this.setState({ loading: true });
      const { project_id } = this.props.appState.project;
      const args = {
        ...values,
        category: values.category!.value,
        subcategory: values.subcategory!.value,
        project_id: project_id,
      };
      const result = await update_project(args);
      this.setState({ loading: false });
      console.log(`result: ${JSON.stringify(result)}`);
      this.props.dispatch(flashMessage("Successfully updated project"));
      this.props.dispatch(fetch_project(project_id));
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;
    const categoryOptions = _.map(project.categories, (cat) => ({
      value: cat.id,
      label: cat.category,
    }));

    const initialCategory = _.find(categoryOptions, {
      value: project.category_id,
    });
    const subcategory = _.find(project.subcategories, {
      id: project.subcategory_id,
    });

    const initialSubcategory = {
      value: subcategory?.id || -1,
      label: subcategory?.subcategory || "-",
    };

    const fallbackCategory = { value: -1, label: "" };

    const get_subcategories = (category: number) =>
      _.map(
        _.filter(project.subcategories, {
          parent_id: category,
        }),
        (subcat) => ({ label: subcat.subcategory, value: subcat.id })
      );

    const optionTypeSchema = Yup.object().shape({
      label: Yup.string().required("required"),
      value: Yup.number().required("required"),
    });

    const validationSchema = Yup.object().shape({
      project_title: Yup.string().required("required"),
      proj_desc: Yup.string().required("required"),
    });

    return (
      <Formik
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        initialValues={{
          project_title: project.title,
          category: initialCategory || fallbackCategory,
          subcategory: initialSubcategory,
          purpose: project.organization,
          proj_desc: project.proj_desc,
        }}
      >
        {({
          handleChange,
          setFieldValue,
          values,
          handleBlur,
          touched,
          errors,
        }) => {
          return (
            <Form className="wf wf-form wf-projectstub" name="projectstub">
              {this.state.loading && "loading"}
              <FormField
                onBlur={handleBlur}
                labelText="Title"
                formName="projectstub"
                onChange={handleChange}
                itemName={"project_title"}
                value={values["project_title"]}
              />
              {errors.project_title && touched.project_title && (
                <div className="wib-error">{errors.project_title}</div>
              )}
              <FormField
                labelText="Category"
                formName="projectstub"
                itemName="category"
                value=""
                customInput={() => (
                  <Select
                    options={categoryOptions}
                    value={values.category}
                    onChange={(val: any) => {
                      setFieldValue("category", val);
                      setFieldValue(
                        "subcategory",
                        _.sample(get_subcategories(val.value))
                      );
                    }}
                  />
                )}
              />
              <FormField
                labelText="Subcategory"
                formName="projectstub"
                itemName="subcategory"
                value={values.subcategory}
                customInput={({ value }) => (
                  <Select
                    options={get_subcategories(values.category!.value)}
                    value={value}
                    onChange={(val) => setFieldValue("subcategory", val)}
                  />
                )}
              />
              <FormField
                labelText="Organization (optional)"
                formName="projectstub"
                onChange={handleChange}
                itemName={"purpose"}
                value={values.purpose}
                customInput={({ value, className }) => (
                  <textarea
                    name="purpose"
                    className={className}
                    value={value}
                    onChange={handleChange}
                  />
                )}
              />
              <FormField
                labelText="Description"
                formName="projectstub"
                onChange={handleChange}
                itemName={"proj_desc"}
                value={values.proj_desc}
                customInput={({ className, value }) => (
                  <textarea
                    onBlur={handleBlur}
                    name="proj_desc"
                    className={className}
                    value={value}
                    onChange={handleChange}
                  />
                )}
              />
              {errors.proj_desc && touched.proj_desc && (
                <div className="wib-error">{errors.proj_desc}</div>
              )}
              <FormSubmit
                disabled={this.state.loading}
                value="submit"
                formName="projectstub"
                itemName="submit"
              />
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default connector(EditProjectStub);
