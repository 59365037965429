import FullUser, { initial_user } from "../types/FullUser";
import { Dispatch, AnyAction } from "redux";
import localStorage, { get as getLS, set as setLS } from "local-storage";
import login_request from "../requests/login";

export const LOGIN = "wib/user/LOGIN";
export const LOGIN_SUCCESS = "wib/user/LOGIN_SUCCESS";

export type LoginSuccessAction = { type: string; currentUser: FullUser };

export interface UserState {
  isAuthenticated: boolean;
  currentUser: FullUser;
}

export default function reducer(
  state: UserState = { isAuthenticated: false, currentUser: initial_user },
  action: AnyAction
): UserState {
  switch (action.type) {
    case LOGIN_SUCCESS:
      const { currentUser } = action as LoginSuccessAction;
      return { isAuthenticated: true, currentUser };
    default:
      return state;
  }
}

export async function login(username: string, password: string) {
  return async function (dispatch: Dispatch) {
    try {
      const response = await login_request(username, password);
      setLS("token", response.token);
      dispatch(login_success(response.user));
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };
}

export function login_success(currentUser: FullUser) {
  return { type: LOGIN_SUCCESS, currentUser };
}
