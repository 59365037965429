import base_url from "./base_url";
import request from "./request_helper";

export interface Args {
  id: number;
  primary_email: string;
  username: string;
  firstname: string;
  lastname: string;
  user_temp: number;
  activity_current_title: string;
  user_current_activity: string;
  user_status: string;
  addtl_info: string;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "PUT",
    baseURL: base_url,
    url: `/users`,
    data: args,
  });
}
