import React from "react";
import Title from "./Title";
import Projects from "./Projects";
import Roles from "./Roles";
import UserDetails from "./UserDetails";
import connector, { IProps } from "../../common_connector";
import { match } from "react-router";
import Spinner from "react-spinners/GridLoader";
import { fetch_profile } from "../../ducks/profile";
import _ from "lodash";
import EditIcon from "../Atoms/EditIcon";
import getCurrentUserID from "../../getCurrentUserID";
import WibModal from "../Atoms/WibModal";
import EditProfile from "../Forms/EditProfile";
import Tags from "../Tags";
import TagFormat from "../../types/TagFormat";
import update_profile_image from "../../requests/update_profile_image";

interface Props extends IProps {
  match: match<{ user_id: string }>;
}

interface State {
  loading: boolean;
  editing: boolean;
  editingImage: boolean;
}

class Profile extends React.Component<Props, State> {
  state = { loading: true, editing: false, editingImage: false };

  refresh = (uid: string) => {
    const user_id = parseInt(uid);
    if (isNaN(user_id)) {
      return;
    }
    this.props.dispatch(fetch_profile(user_id));
    this.setState({ loading: false, editingImage: false });
  };

  handleImageChange = async (image: File) => {
    try {
      const formData = new FormData();
      formData.append("photo", image);
      console.log(formData);
      await update_profile_image(formData);
      this.refresh(this.props.match.params.user_id);
    } catch (err) {}
  };

  componentDidMount() {
    this.refresh(this.props.match.params.user_id);
  }

  componentWillReceiveProps(newProps: Props) {
    if (this.props.match.params.user_id !== newProps.match.params.user_id) {
      this.refresh(newProps.match.params.user_id);
    }
  }

  render() {
    const { profile } = this.props.appState;
    return (
      <>
        <WibModal
          title="Edit Profile"
          isOpen={this.state.editing}
          onDismiss={() => this.setState({ editing: false })}
        >
          <EditProfile onSuccess={() => this.setState({ editing: false })} />
        </WibModal>
        {this.state.loading ? (
          <Spinner loading={this.state.loading} />
        ) : (
          <div className="profile-content wib-content wib-context-profile">
            <Title
              onClick={() => {
                if (profile.id === getCurrentUserID()) {
                  this.setState({ editing: true });
                }
              }}
              name={`${profile.firstname} ${profile.lastname}`}
            >
              {getCurrentUserID() ===
                parseInt(this.props.match.params.user_id) && <EditIcon />}
            </Title>
            <UserDetails
              canEdit={
                getCurrentUserID() === parseInt(this.props.match.params.user_id)
              }
              editingImage={this.state.editingImage}
              onImageEditToggle={() =>
                this.setState({ editingImage: !this.state.editingImage })
              }
              onChangeImage={this.handleImageChange}
              user={profile}
            />
            <div className="collection-item-title">
              <h2 className="profile-content-header">
                Roles & Responsibilities | Skills
              </h2>
            </div>
            <Roles roles={profile.profile_roles} />
            <div className="collection-item-title">
              <h2 className="profile-content-header">Projects</h2>
            </div>
            <Projects projects={profile.projects} />
            <Tags
              format={TagFormat.clist}
              users={profile.collabs.map((collab) => ({
                user_id: collab.collaborator_id,
                username: collab.username,
              }))}
              tags={profile.tags}
            />
          </div>
        )}
      </>
    );
  }
}

export default connector(Profile);
