import React, { useState } from "react";
import IContainer from "../../../types/Container";
import WibMediaViewer from "../../Atoms/WIBMediaViewer";
import path from "path";
import { MODULE_CONTENT_URL } from "../../../requests/base_url";
import DeleteIcon from "../../Atoms/DeleteIcon";
import Axios from "axios";
import { containerCSS } from "react-select/src/components/containers";
import ReactPlayer from "react-player";
import Editor from "react-simple-code-editor";
import highlight from "highlight.js";

interface Props {
  container: IContainer;
  onDelete?: VoidFunction;
  onContentChange: (content: string) => void;
  key: string;
}

interface State {
  loadedText: string;
  isText: boolean;
  isDirty: boolean;
  isCode: boolean;
  codeValue: string;
}

export default class Container extends React.Component<Props, State> {
  state = {
    loadedText: "",
    isText: path.extname(this.props.container.content_loc) === ".txt",
    isCode: path.extname(this.props.container.content_loc) === ".code",
    codeValue: "",
    isDirty: false,
  };

  async componentDidMount() {
    if (this.state.isText || this.state.isCode) {
      const loadedText = (
        await Axios.get(
          `${MODULE_CONTENT_URL}/${this.props.container.content_loc}`
        )
      ).data;
      this.setState({ loadedText, codeValue: loadedText });
    }
  }

  render() {
    return (
      <div
        className={`wib-container wib-container-content-${this.props.container.content_type}`}
      >
        {this.state.isCode && (
          <div className="codeinput">
            <Editor
              onBlur={() => {
                if (this.state.isDirty) {
                  this.setState({ isDirty: false });
                  this.props.onContentChange(this.state.codeValue);
                }
              }}
              onValueChange={(codeValue) =>
                this.setState({ codeValue, isDirty: true })
              }
              value={this.state.codeValue}
              highlight={(code) => highlight.highlightAuto(code).value}
            >
              {this.state.loadedText}
            </Editor>
          </div>
        )}
        {this.state.isText ? (
          <pre>
            <div
              contentEditable
              onKeyUp={(e) => this.setState({ isDirty: true })}
              onBlur={(e) => {
                if (this.state.isDirty) {
                  this.setState({ isDirty: false });
                  this.props.onContentChange(e.target.innerText);
                }
              }}
              className={"textinput"}
            >
              {this.state.loadedText}
            </div>
          </pre>
        ) : this.props.container.content_type == 5 ? (
          <div className="video-player">
            <ReactPlayer url={this.props.container.content_loc} />
          </div>
        ) : (
          <WibMediaViewer
            docName={path.basename(this.props.container.content_loc)}
            docPath={`${MODULE_CONTENT_URL}/${this.props.container.content_loc}`}
          />
        )}
        <DeleteIcon onClick={this.props.onDelete} />
      </div>
    );
  }
}
