import React, { ReactElement } from "react";
import _ from "lodash";
import Text from "../Atoms/Text";
import ProfileRole from "../../types/ProfileRole";
import { Link } from "react-router-dom";

interface Props {
  roles: ProfileRole[];
}

export default function Roles(props: Props): ReactElement {
  return (
    <div>
      <div className="profile-role-links">
        {props.roles.map((role) => (
          <div
            key={`${role.role}-${role.role_id}`}
            className="profile-role-focus-container"
          >
            <Text name="profile-role-link">
              <Link to={`/projects/${role.project_id}`}>{role.role}</Link>
            </Text>
            <Text name="profile-focus-text">{role.focus}</Text>
          </div>
        ))}
      </div>
      {/* <div className="profile-role-interests">
        {_.times(15, () => (
          <Text name="profile-role-interest">Interest </Text>
        ))}
      </div> */}
    </div>
  );
}
