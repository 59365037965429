import React, { useState } from "react";
import { Dispatch } from "redux";
import TitleBar from "./TitleBar";
import Objective from "./Objective";
import Category from "./Category";
import EditProjectStub from "../../Forms/EditProjectStub";
import UserContext from "../../../UserContext";
import WibModal from "../../Atoms/WibModal";
import { fetch_project } from "../../../ducks/project";
import { connect } from "react-redux";
import AppState from "../../../types/AppState";
import { ThunkDispatch } from "redux-thunk";

interface Props {
  canEdit: () => boolean;
  refresh: VoidFunction;
}

const ProjectStub = (props: any) => {
  const [editing, set_editing] = useState(false);
  return (
    <>
      <WibModal
        title="Edit Project"
        isOpen={editing}
        onDismiss={() => set_editing(false)}
      >
        <EditProjectStub onSuccess={() => set_editing(false)} />
      </WibModal>
      <div
        onClick={() => {
          if (props.canEdit()) {
            set_editing(true);
          }
        }}
        className="projectstub wib-collection"
        style={{ cursor: "pointer" }}
      >
        <div className="wib-item-group">
          <TitleBar />
          <Objective />
          <Category />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (appState: AppState) => ({});
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, any, any>) => ({
  dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectStub);
