import { get as lsGet } from "local-storage";
import jwt_decode from "jwt-decode";

export default () => {
  const token = lsGet<string>("token");
  const decoded =
    token !== null
      ? jwt_decode<{ exp: number; user_id: number }>(token)
      : { exp: 0, user_id: -1 };
  return decoded.user_id;
};
