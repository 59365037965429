import React, { useState } from "react";
import Dropzone, { FileRejection, DropEvent } from "react-dropzone";
import Text from "../../Atoms/Text";

interface Props {
  fileType?: string;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
}

export const FileDropper = (props: Props) => {
  const [hasFile, setHasFile] = useState(false);
  const [fileName, setFileName] = useState("");

  return (
    <Dropzone
      onDrop={(acceptedFiles, fileRejections, event) => {
        setHasFile(true);
        setFileName(acceptedFiles.map((file) => file.name).join(","));
        props.onDrop(acceptedFiles, fileRejections, event);
      }}
      accept={props.fileType}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()} className="wib-dropzone-area">
          <input {...getInputProps()} />
          <Text name="dropzone-message">
            {hasFile ? (
              fileName
            ) : isDragActive ? (
              "Drop 'em here!"
            ) : (
              <span className="wib-dropzone-filetype">
                Drag / Drop {props.fileType?.split("/")[0]} Files
              </span>
            )}
          </Text>
        </div>
      )}
    </Dropzone>
  );
};
