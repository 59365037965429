import BASE_URL from "./base_url";
import request from "./request_helper";

export interface Input {
  project_id: number;
  user_id: number;
  collab_type: number;
}

export default async function execute(args: Input): Promise<any> {
  return request({
    baseURL: BASE_URL,
    url: "projects/collab_type",
    method: "put",
    data: args,
  });
}
