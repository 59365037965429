import React from "react";
import { clear } from "local-storage";
import { Link } from "react-router-dom";

export default (props: any) => (
  <div className="wib-footer">
    <div className="wib-copyright-container">
      <div className="wib-copyright">
        <p>&copy; whatimbuilding.com</p>
      </div>
    </div>
    <div className="wib-footnav-container">
      <div className="wib-footnav">
        <div className="wib-footnav-item-container wib-footnav-item1-container">
          <a className="wib-nav-item wib-footnav-item wib-footnav-item1" href="#">
            link1
          </a>
        </div>
        <div className="wib-footnav-item-container wib-footnav-item1-container">
          <a className="wib-nav-item wib-footnav-item wib-footnav-item1" href="#">
            link2
          </a>
        </div>
        <div className="wib-footnav-item-container wib-footnav-item3-container">
          <a className="wib-nav-item wib-footnav-item wib-footnav-item3" href="#">
            link3
          </a>
        </div>
        <div className="wib-footnav-item-container wib-footnav-item4-container">
          <a className="wib-nav-item wib-footnav-item wib-footnav-item4" href="#">
            link4
          </a>
        </div>
      </div>
    </div>
    <div className="wib-social-icons-container">
      <div className="wib-social-icons">
        <div className="wib-social-icon-container wib-social-icon1">
          <a href="#">
            <img src="/pixel.png" alt="" />
          </a>
        </div>
        <div className="wib-social-icon-container wib-social-icon2">
          <a href="#">
            <img src="/pixel.png" alt="" />
          </a>
        </div>
        <div className="wib-social-icon-container wib-social-icon3">
          <a href="#">
            <img src="/pixel.png" alt="" />
          </a>
        </div>
        <div className="wib-social-icon-container wib-social-icon4">
          <a href="#">
            <img src="/pixel.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
);
