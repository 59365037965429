import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import getEnv from "./getEnv";

function loadStyle(url: string) {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  const env = getEnv();
  link.href = `https://static.whatimbuilding.com/${env}/${url}`;
  document.head.appendChild(link);
}

loadStyle("/css/skeleton.css");
loadStyle("/css/skin.css");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
