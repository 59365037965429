import React, { useState } from "react";
import Role from "../../../types/Role";
import Text from "../../Atoms/Text";
import EditIcon from "../../Atoms/EditIcon";
import _ from "lodash";
import uniqid from "uniqid";
import DeleteIcon from "../../Atoms/DeleteIcon";
import AddButton from "../../Atoms/AddButton";
import WibConfirmModal from "../../Atoms/WibConfirmModal";
import { Link } from "react-router-dom";

interface Props {
  editRole: () => void;
  role: Role;
  addAssignment: VoidFunction;
  onUnassignUser: (focus_id: number, user_id: number) => void;
  onDeleteFocus: (focus_id: number) => void;
  onDeleteRole: (role_id: number) => void;
  onEditFocus: (role_id: number, focus_id: number) => void;
  // editFocus: (focusID: number) => void;
}

export default (props: Props) => {
  return (
    <div
      className="role"
      style={{ cursor: "pointer" }}
      onClick={() => props.editRole()}
    >
      {_.map(props.role.foci, (focus, index) => {
        return (
          <>
            <div
              className="role-focus-row-container"
              onClick={(e) => {
                // e.stopPropagation();
                // props.editFocus(focus.focus_id);
              }}
            >
              <div className="role-rocus-row-container">
                <div
                  className={`role-focus-row ${
                    props.role.not_pending === 0 ? "wib-role-pending" : ""
                  }`}
                >
                  {index === 0 && (
                    <div className="role-name-container">
                      <div className="wib-role-name-text-container">
                        <div className="wib-actions-container wib-actions-container-rolefocus">
                          <EditIcon />
                          <DeleteIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onDeleteRole(props.role.role_id);
                            }}
                          />
                        </div>
                        <Text name="wib-role-name-text">
                          {props.role.role !== undefined
                            ? props.role.role
                            : "*"}
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`focus-name-container  ${
                  focus.focus === "" && "wib-emptydetails"
                }`}
                onClick={(e) => {
                  props.onEditFocus(props.role.role_id, focus.focus_id);
                  e.stopPropagation();
                }}
              >
                <div className="focus-name-text-container">
                  <Text name={`wib-focus-name-text`}>{focus.focus}</Text>
                  <div className="wib-actions-container wib-actions-container-focus">
                    <EditIcon />
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onDeleteFocus(focus.focus_id);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="focus-users-container"
                onClick={(e) => e.stopPropagation()}
              >
                {focus.users.map((user) => (
                  <div
                    className={`focus-user ${
                      user.project_accepted === 0 ? "focus-user-pending" : ""
                    }`}
                  >
                    <Link
                      to={`/profile/${user.id}`}
                    >{`${user.firstname} ${user.lastname}`}</Link>
                    {user.project_accepted === 0 && (
                      <div className="pending-details"></div>
                    )}
                    <div className="wib-actions-container wib-actions-container-focus-user">
                      <DeleteIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          props.onUnassignUser(focus.focus_id, user.id);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      })}

      <AddButton
        text="Add Assignment"
        onClick={(e) => {
          props.addAssignment();
          e.stopPropagation();
        }}
      />
    </div>
  );
};
