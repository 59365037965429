import React from "react";
import connector, { IProps } from "../../../common_connector";
import ReactQuill, { Quill } from "react-quill";
import Dropzone from "react-dropzone";
import IconButton from "../../Atoms/IconButton";
import WIBError from "../../../types/WIBError";
import {
  flashErrorMessage,
  flashMessage,
  flashSuccessMessage,
} from "redux-flash";
import Text from "../../Atoms/Text";
import _ from "lodash";
import Spinner from "react-spinners/GridLoader";
import FormField from "../../Atoms/FormField";
import FormData from "form-data";
import update_step from "../../../requests/update_step";
import get_stepfiles from "../../../requests/get_stepfiles";
import get_file from "../../../requests/get_file";
import delete_stepfile from "../../../requests/delete_stepfile";
import isImage from "is-image";
import ImageResize from "quill-image-resize";
import { expand_step, collapse_step } from "../../../ducks/expanded";
import WibConfirmModal from "../../Atoms/WibConfirmModal";
import delete_step from "../../../requests/delete_step";
import { fetch_project } from "../../../ducks/project";
import MediaModal from "../../Atoms/WIBMediaModal";
import path from "path";
import WIBMediaViewer from "../../Atoms/WIBMediaViewer";

Quill.register("modules/imageResize", ImageResize);

interface Props extends IProps {
  stepID: number;
  processID: number;
}

interface WIBStepFile {
  name: string;
  path: string;
}

interface State {
  expanded: boolean;
  loading: boolean;
  isContentFetched: boolean;
  stepTitle: string;
  stepAction: string;
  newFiles: File[];
  storedFiles: WIBStepFile[];
  editorContents: any;
  confirming: boolean;
  isMediaShowing: boolean;
  mediaPath: string;
  mediaTitle: string;
}

//const initialEditorVal = new Delta();

class Step extends React.Component<Props, State> {
  editorRef = React.createRef<ReactQuill>();

  constructor(props: Props) {
    super(props);
    const step = this.getStep();
    this.state = {
      expanded: false,
      loading: false,
      isContentFetched: false,
      stepTitle: step.title,
      stepAction: step.action,
      newFiles: [],
      storedFiles: [],
      editorContents: "",
      confirming: false,
      isMediaShowing: false,
      mediaPath: "",
      mediaTitle: "",
    };
  }

  componentDidMount() {
    this.fetchStep();
  }

  deleteStoredFile = async (filename: string) => {
    this.setState({ loading: true });
    const { project_id } = this.props.appState.project;
    const { stepID, processID } = this.props;
    try {
      const response = await delete_stepfile({
        project_id,
        step_id: stepID,
        process_id: processID,
        filename,
      });
      if (response.success) {
        this.props.dispatch(flashMessage("Successfully deleted file"));
        this.setState({
          loading: false,
          storedFiles: this.state.storedFiles.filter(
            (file) => file.name !== filename
          ),
        });
      }
    } catch (err) {
      const error = err as WIBError;
      console.log(error);
      this.setState({ loading: false });
      // this.props.dispatch(
      //   flashErrorMessage(`Could not delete file (code: ${error.code})`)
      // );
    }
  };

  fetchStep = async () => {
    this.setState({ loading: true, isContentFetched: false });
    try {
      const files = await get_stepfiles({
        project_id: this.props.appState.project.project_id,
        process_id: this.props.processID,
        step_id: this.props.stepID,
      });
      const text_url = _.find(files, { name: "step.html" })?.path;
      let editorContents = "";
      if (text_url !== undefined) {
        editorContents = await get_file(text_url);
      }
      console.log(`editorcontents: ${editorContents}`);
      this.setState({
        loading: false,
        isContentFetched: true,
        storedFiles: files,
        editorContents,
      });
    } catch (err) {
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`Could not fetch step info, (code: ${error.code})`)
      );
      this.setState({ loading: false });
    }
  };

  getProcess = () =>
    _.find(this.props.appState.project.processes, {
      id: this.props.processID,
    })!;

  getStep = () => {
    const process = _.find(this.props.appState.project.processes, {
      id: this.props.processID,
    });
    return _.find(process!.steps, { id: this.props.stepID })!;
  };

  handleExpandCollapse = async (expanded: boolean) => {
    expanded
      ? this.props.dispatch(
          collapse_step(this.props.processID, this.props.stepID)
        )
      : this.props.dispatch(
          expand_step(this.props.processID, this.props.stepID)
        );
    if (!this.state.isContentFetched) {
      try {
        await this.fetchStep();
      } catch (err) {
        console.log(`err: ${err}`);
      }
    }
  };

  toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleDroppedFiles = async (files: File[]) => {
    console.log(JSON.stringify(files));
    // files.forEach(async (file) => {
    //   if (isImage(file.name) && !(path.extname(file.name) === ".stl")) {
    //     const base64 = await this.toBase64(file);
    //     const imgTag = `<img src=${base64} />`;
    //     this.setState({ editorContents: this.state.editorContents + imgTag });
    //   }
    // });
    this.setState({
      newFiles: [
        ...this.state.newFiles,
        ...files,
        // ...files.filter(
        //   (file) =>
        //     !(isImage(file.name) && !(path.extname(file.name) === ".stl"))
        // ),
      ],
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const body: { [key: string]: any } = {
      step_id: this.props.stepID,
      owner_id: 4,
      title: this.state.stepTitle,
      action: this.state.stepAction,
      process_id: this.props.processID,
      project_id: this.props.appState.project.project_id,
    };
    const files = [
      ...this.state.newFiles,
      new File([this.state.editorContents], "step.html"),
    ];
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    files.forEach((file) => formData.append("files[]", file));
    try {
      const response = await update_step(formData);
      console.log(response);
      this.setState({ loading: false });
      this.setState({ newFiles: [] });
      this.fetchStep();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(flashErrorMessage(error.message));
    }
  };

  getExpanded = (props: Props) => {
    const step = this.getStep();
    const expanded_process = _.find(props.appState.expanded, {
      process_id: props.processID,
    });
    return (
      expanded_process !== undefined && expanded_process.steps.includes(step.id)
    );
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    return state;
  }

  deleteStep = async () => {
    const { project } = this.props.appState;
    try {
      const deleteFilesReqs = await Promise.all(
        this.state.storedFiles.map(
          async (file) => await this.deleteStoredFile(file.name)
        )
      );
      await delete_step({
        project_id: project.project_id,
        step_id: this.props.stepID,
      });
      this.setState({ confirming: false });
      this.props.dispatch(flashSuccessMessage("Successfully deleted step"));
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(
        flashErrorMessage("An error occured while deleting the step")
      );
    }
  };

  render() {
    const { project } = this.props.appState;
    const step = this.getStep();
    const expanded = this.getExpanded(this.props);
    return (
      <div
        className={`step-container step-container-${
          expanded ? "expanded" : "collapsed"
        }`}
      >
        <MediaModal
          docName={this.state.mediaTitle}
          docPath={this.state.mediaPath}
          isOpen={this.state.isMediaShowing}
          onDismiss={() => this.setState({ isMediaShowing: false })}
        />
        <WibConfirmModal
          onCancel={() => this.setState({ confirming: false })}
          onConfirm={this.deleteStep}
          isOpen={this.state.confirming}
        />
        <div
          className="step-stub-container"
          onClick={() => this.handleExpandCollapse(expanded)}
        >
          <IconButton
            onClick={() => this.handleExpandCollapse(expanded)}
            name={`expand-chevron expand-chevron-${
              expanded ? "expanded" : "collapsed"
            }`}
          />
          <Text name="step-title">{step.title}</Text>
          <Text name="step-action">{step.action}</Text>
        </div>
        <div
          className={`step-content step-content${
            expanded ? "expanded" : "collapsed"
          }`}
          style={{ display: expanded ? "block" : "none" }}
        >
          {project.isAdmin && (
            <FormField
              formName="Step"
              itemName="step-title"
              labelText="Step Title"
              value={this.state.stepTitle}
              onChange={(e) => this.setState({ stepTitle: e.target.value })}
            />
          )}

          {project.isAdmin && (
            <FormField
              formName="Step"
              itemName="step-action"
              labelText="Description"
              value={this.state.stepAction}
              customInput={({ className, value }) => (
                <textarea
                  className={className}
                  value={value}
                  onChange={(e) =>
                    this.setState({ stepAction: e.target.value })
                  }
                />
              )}
            />
          )}
          <ReactQuill
            readOnly={!project.isAdmin}
            ref={this.editorRef}
            value={this.state.editorContents}
            onChange={(content, delta, source, editor) => {
              this.setState({ editorContents: content });
              this.editorRef.current?.getEditor().focus();
            }}
          />

          <div className="wib-step-storedfiles-container">
            {this.state.storedFiles.map(
              (file) =>
                file.name !== "step.html" && (
                  <div
                    className="wib-step-storedfile-container"
                    key={file.path}
                  >
                    <button
                      className="wib-step-storedfile-removebutton"
                      onClick={() => this.deleteStoredFile(file.name)}
                    >
                      -
                    </button>

                    {/* <button
                      onClick={() =>
                        this.setState({
                          isMediaShowing: true,
                          mediaPath: file.path,
                          mediaTitle: file.name,
                        })
                      }
                    >
                      <Text name="wib-step-stored-file">{file.name}</Text>
                    </button> */}
                    <WIBMediaViewer docName={file.name} docPath={file.path} />
                  </div>
                )
            )}
          </div>

          {project.isAdmin && (
            <>
              <Dropzone onDrop={this.handleDroppedFiles}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div {...getRootProps()} className="wib-dropzone-area">
                    <input {...getInputProps()} />
                    <Text name="dropzone-message">
                      {isDragActive
                        ? "Drop 'em here!"
                        : "Drop files related to this step"}
                    </Text>
                  </div>
                )}
              </Dropzone>
              <div className="wib-step-newfiles-container">
                {this.state.newFiles.map((file) => (
                  <div className="wib-step-newfile-container">
                    <Text name="wib-step-new-file">{file.name}</Text>
                    <button
                      className="wib-step-newfile-removebutton"
                      onClick={() =>
                        this.setState({
                          newFiles: this.state.newFiles.filter(
                            (f) => f.name !== file.name
                          ),
                        })
                      }
                    >
                      -
                    </button>
                  </div>
                ))}
              </div>
              <div className="wib-buttons-container wib-buttons-container-process">
                <div className="wib-button-container wib-button-submit-container">
                  <button
                    className="wib-button wib-button-submit wib-step-submit"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                <div className="wib-button-container wib-button-delete-container">
                  <button
                    onClick={() => this.setState({ confirming: true })}
                    className="wib-button wib-button-delete wib-step-delete"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </>
          )}
          <Spinner loading={this.state.loading} color="#132a34" />
        </div>
      </div>
    );
  }
}

export default connector(Step);
