import base_url from "./base_url";
import request from "./request_helper";

export default async function execute(args: any): Promise<any> {
  return await request({
    method: "PUT",
    baseURL: base_url,
    url: `/users/profile_image`,
    data: args,
  });
}
