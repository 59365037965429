import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import WibModal, { Props as ModalProps } from "./WibModal";

export interface Props {
  docPath: string;
}

interface State {
  currentPage: number;
  numPages: number;
}

export default class PDFView extends Component<Props, State> {
  state = { currentPage: 1, numPages: 0 };

  render() {
    return (
      <div className="wib-pdf-view">
        <Document
          file={this.props.docPath}
          onLoadSuccess={({ numPages }) => this.setState({ numPages })}
        >
          <Page pageNumber={this.state.currentPage} />
        </Document>
        <div className="wib-action-container">
          {this.state.currentPage > 1 && (
            <button
              className="wib-button wib-button-previouspage"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  currentPage: this.state.currentPage - 1,
                });
              }}
            >
              previous
            </button>
          )}
          {this.state.currentPage < this.state.numPages && (
            <button
              className="wib-button wib-button-nextpage"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  currentPage: this.state.currentPage + 1,
                });
              }}
            >
              next
            </button>
          )}
        </div>
      </div>
    );
  }
}
