import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select, { OptionTypeBase } from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import TagsInput from "react-tagsinput";
import update_process from "../../requests/update_process";
import create_process from "../../requests/create_process";
import { fetch_project } from "../../ducks/project";
import CreatableSelect from "react-select/creatable";
import getCurrentUserID from "../../getCurrentUserID";
import * as Yup from "yup";

interface Props extends IProps {
  onSuccess?: VoidFunction;
  selectedProcess: number;
  editing: boolean;
}

type Values = {
  tags: OptionTypeBase[];
  role: OptionTypeBase;
  title: string;
  objective: string;
};

interface State {
  loading: boolean;
}

class Process extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  handleSubmit = async (values: Values) => {
    const { project } = this.props.appState;
    try {
      const req_args = {
        owner_id: _.sample(project.users)!.user_id,
        role_id: values.role.value,
        title: values.title,
        objective: values.objective,
        tags: values.tags.map((tag) => tag.value).join(","),
      };
      this.setState({ loading: true });
      if (this.props.editing) {
        await update_process({
          ...req_args,
          id: this.props.selectedProcess,
          project_id: project.project_id,
          user_id: getCurrentUserID(),
        });
      } else {
        await create_process({
          project_id: project.project_id,
          ...req_args,
        });
      }
      this.setState({ loading: false });
      this.props.dispatch(
        flashMessage(
          `Successfully ${this.props.editing ? "edited" : "created"} Process`
        )
      );
      this.props.dispatch(fetch_project(project.project_id));
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      this.setState({ loading: false });
      const error = err as WIBError;
      this.props.dispatch(
        flashErrorMessage(`${error.message} (code: ${error.code})`)
      );
    }
  };

  render() {
    const { project } = this.props.appState;

    const optionTypeSchema = Yup.object().shape({
      label: Yup.string().required("required"),
      value: Yup.number().min(0).required("required"),
    });

    const validationSchema = Yup.object().shape({
      title: Yup.string().required("required"),
      objective: Yup.string().required("required"),
      role: Yup.object().shape({
        label: Yup.string().required("required"),
        value: Yup.number().min(0).required("required"),
      }),
    });

    let initialValues: Values = {
      tags: [],
      role: { value: -1, label: "-" },
      title: "",
      objective: "",
    };

    const role_options = project.roles.map((role) => ({
      value: role.role_id,
      label: role.role,
    }));

    if (this.props.editing) {
      const process = _.find(project.processes, {
        id: this.props.selectedProcess,
      })!;
      initialValues = {
        ...initialValues,
        title: process.title,
        objective: process.objective,
      };
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          setFieldTouched,
        }) => (
          <Form className="wf wf-form wf-process" name="process">
            <FormField
              onBlur={handleBlur}
              labelText="Title"
              formName="process"
              itemName="title"
              value={values.title}
              onChange={handleChange}
            />
            {errors.title && touched.title && (
              <div className="wib-error">{errors.title}</div>
            )}
            <FormField
              labelText="Objective"
              formName="process"
              itemName="objective"
              value={values.objective}
              onChange={handleChange}
              customInput={({ value, className }) => (
                <textarea
                  onBlur={handleBlur}
                  name="objective"
                  className={className}
                  value={value}
                  onChange={handleChange}
                />
              )}
            />
            {errors.objective && touched.objective && (
              <div className="wib-error">{errors.objective}</div>
            )}
            <div
              className="process-form-role"
              style={{ display: this.props.editing ? "none" : "block" }}
            >
              <FormField
                labelText="role"
                formName="process"
                itemName="role"
                value={values.role}
                onChange={handleChange}
                customInput={({ value }) => (
                  <Select
                    onBlur={() => setFieldTouched("role")}
                    isDisabled={this.props.editing}
                    options={role_options}
                    value={value}
                    onChange={(role: OptionTypeBase) =>
                      setFieldValue("role", role)
                    }
                  />
                )}
              />
            </div>
            {errors.role && <div className="wib-error">required</div>}
            <div className="process-form-tags">
              <FormField
                labelText="tags"
                formName="process"
                itemName="tags"
                value={values.tags}
                onChange={handleChange}
                customInput={({ value }) => (
                  <CreatableSelect
                    isMulti
                    isDisabled={this.props.editing}
                    value={value}
                    onChange={(tags: OptionTypeBase) =>
                      setFieldValue("tags", tags)
                    }
                  />
                )}
              />
            </div>
            <FormSubmit
              disabled={this.state.loading}
              formName="process"
              labelText="Submit"
              itemName="submit"
              value="submit"
            />
            {this.state.loading && "loading"}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(Process);
