import React, { FormEvent } from "react";
import FormField from "./Atoms/FormField";
import AppState from "../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "./Atoms/FormSubmit";
import connector, { IProps } from "../common_connector";
import _ from "lodash";
import update_integration from "../requests/update_integration";
import WIBError from "../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import { Redirect, Link } from "react-router-dom";
import { login_success } from "../ducks/user";
import login_request from "../requests/login";
import { set as lsSet } from "local-storage";
import UserContext from "../UserContext";

interface Props extends IProps {
  onSuccess?: VoidFunction;
}

type Values = {
  username: string;
  password: string;
};

interface State {
  loading: boolean;
  success: boolean;
}

class Login extends React.Component<Props, State> {
  state = {
    loading: false,
    success: false,
  };

  handleSubmit = (setNeedsLogin: (a: boolean) => void) => async (
    values: Values
  ) => {
    try {
      this.setState({ loading: true });
      const login_response = await login_request(
        values.username,
        values.password
      );
      console.log(login_response);
      lsSet("token", login_response.token);
      /* await request(...) */

      this.setState({ loading: false, success: true });
      this.props.dispatch(login_success(login_response.user));
      setNeedsLogin(false);
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      const error = err as WIBError;
      if (error.additional_info === 403) {
        this.props.dispatch(
          flashErrorMessage("No user exists with these credentials")
        );
      }
      this.setState({ loading: false });
    }
  };

  render() {
    const { project } = this.props.appState;

    const initialValues = {
      username: "",
      password: "",
    };

    return this.state.success ? (
      <Redirect to={`/search`} />
    ) : (
      <UserContext.Consumer>
        {({ setNeedsLogin }) => (
          <>
            <Formik
              initialValues={initialValues}
              onSubmit={this.handleSubmit(setNeedsLogin)}
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form className="wf wf-form wf-login" name="login">
                  <FormField
                    labelText="Email"
                    formName="login"
                    itemName="username"
                    value={values.username}
                    onChange={handleChange}
                  />
                  <FormField
                    labelText="Password"
                    formName="login"
                    itemName="password"
                    value={values.password}
                    onChange={handleChange}
                    type="password"
                  />
                  <FormSubmit
                    disabled={this.state.loading}
                    formName="formname"
                    labelText="Submit"
                    itemName="submit"
                    value="submit"
                  />
                  {/* {this.state.loading && <Spinner size={SpinnerSize.large} />} */}
                </Form>
              )}
            </Formik>
            <div className="wib-button-container wib-button-container-sinup wff-wrap wff-wrap-text wff-wrap-signup">
              <Link to="/?signup=1" className="wib-button wib-button-signup">
                Signup
              </Link>
            </div>
            <div className="wib-button-container wib-button-container-forgot-password wff-wrap wff-wrap-text wff-wrap-forgotpassword">
              <Link
                to="/?forgotpassword=1"
                className="wib-button wib-button-forgot-password"
              >
                Forgot Password
              </Link>
            </div>
          </>
        )}
      </UserContext.Consumer>
    );
  }
}

export default connector(Login);
