import base_url from "./base_url";
import request from "./request_helper";
import qs from "querystring";

export interface Args {
  email: string;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "GET",
    baseURL: base_url,
    url: `/users/0/username_exists?username=${args.email}`,
    data: args,
  });
}
