import React, { FormEvent } from "react";
import FormField from "../Atoms/FormField";
import AppState from "../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Form, Formik, FormikHandlers } from "formik";
import Select from "react-select";
import FormSubmit from "../Atoms/FormSubmit";
import connector, { IProps } from "../../common_connector";
import _ from "lodash";
import update_integration from "../../requests/update_integration";
import WIBError from "../../types/WIBError";
import { flashMessage, flashErrorMessage } from "redux-flash";
import Spinner from "react-spinners/GridLoader";
import querystring from "querystring";
import get_user from "../../requests/get_user";
import get_project_stub from "../../requests/get_project_stub";
import get_focus_list from "../../requests/get_focus_list";
import FullUser, { initial_user } from "../../types/FullUser";
import accept_invite from "../../requests/accept_invite";
import { Redirect } from "react-router";
import * as Yup from "yup";
import user_accept from "../../requests/user_accept";
import UserContext from "../../UserContext";
import { set as lsSet } from "local-storage";
import Validators from "../../validators";

interface Props extends IProps {
  onSuccess?: VoidFunction;
}

type Values = {
  firstname: string;
  lastname: string;
  username: string;
  // primary_email: string;
  password: string;
};

type FocusList = {
  role_id: number;
  role: string;
  focus_id: number;
  focus: string;
  user_count: number;
}[];

interface State {
  loading_submit: boolean;
  loading_initial: boolean;
  focus_list: FocusList;
  user: FullUser;
  success: boolean;
}

class AcceptInvite extends React.Component<Props, State> {
  state = {
    loading_submit: false,
    loading_initial: true,
    focus_list: [],
    user: initial_user,
    success: false,
  };
  handleSubmit = (setNeedsLogin: (arg0: boolean) => void) => async (values: Values) => {
    console.log(`values: ${JSON.stringify(values)}`);
    try {
      this.setState({ loading_submit: true });
      const { temp, notid } = this.extractLocation();
      const response = await user_accept({
        user_temp: temp,
        notification_id: notid,
        firstname: values.firstname,
        lastname: values.lastname,
        primary_email: "",
        username: values.username,
        password: values.password,
      });
      if (response.token) {
        setNeedsLogin(false);
        lsSet("token", response.token);
      }
      this.props.dispatch(flashMessage(`Successfully Accepted Invite`));
      this.props.onSuccess && this.props.onSuccess();
    } catch (err) {
      this.props.dispatch(flashErrorMessage(`${err.message}`));
      this.setState({ loading_submit: false });
      const error = err as WIBError;
      // this.props.dispatch(
      //   flashErrorMessage(`${error.message} (code: ${error.code})`)
      // );
    }
  };

  extractLocation = () => {
    const search = _.replace(window.location.search, "?", "");
    const { temp, notid } = querystring.parse(search);

    return { temp: parseInt(temp as string), notid: parseInt(notid as string) };
  };

  respondToInvite = async (response: number) => {
    const { project } = this.props.appState;
    this.setState({ loading_submit: true });
    try {
      await accept_invite({
        ...this.extractLocation(),
        accept: 1,
        isNewUser: this.state.user.user_temp === 1,
      });
      this.setState({ success: true });
    } catch (err) {
      this.props.dispatch(flashErrorMessage("An error occured while errorMessage"));
    }
  };

  render() {
    const { temp, notid } = this.extractLocation();
    const initialValues: Values = {
      firstname: "",
      lastname: "",
      username: "",
      // primary_email: "",
      password: "",
    };

    const validationSchema = Yup.object().shape({
      firstname: Yup.string().required("required"),
      lastname: Yup.string().required("required"),
      username: Validators.usernameValidator,
      // primary_email: Validators.emailValidator,
      password: Yup.string().min(6, "password must be at least 6 characters long"),
    });

    return (
      <UserContext.Consumer>
        {({ setNeedsLogin }) =>
          temp === 1 ? (
            <Formik
              initialValues={initialValues}
              onSubmit={this.handleSubmit(setNeedsLogin)}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                <Form className="wf wf-form wf-acceptinvite wib-context-acceptinvite" name="acceptinvite">
                  <FormField
                    onBlur={handleBlur}
                    labelText="First Name"
                    formName="acceptinvite"
                    itemName="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                  />
                  {errors.firstname && touched.firstname && <div className="wib-error">{errors.firstname}</div>}
                  <FormField
                    onBlur={handleBlur}
                    labelText="Last Name"
                    formName="acceptinvite"
                    itemName="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                  />
                  {errors.lastname && touched.lastname && <div className="wib-error">{errors.lastname}</div>}
                  {/* {
                  <FormField
                    onBlur={handleBlur}
                    labelText="Email"
                    formName="acceptinvite"
                    itemName="primary_email"
                    value={values.primary_email}
                    onChange={handleChange}
                  />
                  {errors.primary_email && touched.primary_email && (
                    <div className="wib-error">{errors.primary_email}</div>
                  )}} */}
                  <FormField
                    onBlur={handleBlur}
                    labelText="Username"
                    formName="acceptinvite"
                    itemName="username"
                    value={values.username}
                    onChange={handleChange}
                  />
                  {errors.username && touched.username && <div className="wib-error">{errors.username}</div>}
                  <FormField
                    onBlur={handleBlur}
                    labelText="Password"
                    formName="acceptinvite"
                    itemName="password"
                    value={values.password}
                    onChange={handleChange}
                    type="password"
                  />
                  {errors.password && touched.password && <div className="wib-error">{errors.password}</div>}
                  <FormSubmit
                    disabled={this.state.loading_submit}
                    formName="acceptinvite"
                    labelText="Submit"
                    itemName="submit"
                    value="submit"
                  />
                </Form>
              )}
            </Formik>
          ) : (
            <div className="wib-button-group">
              <button className="wib-accept-button" onClick={() => this.handleSubmit(setNeedsLogin)(initialValues)}>
                Accept
              </button>
              <button className="wib-decline-button" onClick={() => this.props.onSuccess && this.props.onSuccess()}>
                Decline
              </button>
            </div>
          )
        }
      </UserContext.Consumer>
    );
  }
}

export default connector(AcceptInvite);
