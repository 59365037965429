import React, { MouseEventHandler } from "react";
import Add from "./AddButton/add.svg";

export default (props: {
  name: string;
  text?: string;
  onClick?: MouseEventHandler;
}) => (
  <div
    className={`wib-${props.name}-container`}
    style={{ cursor: "pointer" }}
    onClick={props.onClick}
  >
    <img src="" alt={props.name} />
    <div className={`wib-${props.name}-text`}>{props.text}</div>
  </div>
);
