export default class WIBError {
  code: string;
  additional_info: any;
  message: string;
  constructor(message: string, code: string, additional_info: any) {
    this.message = message;
    this.code = code;
    this.additional_info = additional_info;
  }
}
