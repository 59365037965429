import base_url from "./base_url";
import request from "./request_helper";

export interface Args {
  project_id: number;
  role_id: number;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "DELETE",
    baseURL: base_url,
    url: `/projects/role`,
    data: args,
  });
}
